import React from "react";
import "./_management.scss";

import useAxiosGet from "../components/Axios/useAxiosGet";
import HeaderPages from "../components/Header/HeaderPages";
import ManagementCard from "../components/Cards/ManagmentCard";

const Management = () => {
  const urlManagement = "/zarzad";

  const {
    response: managment,
    isLoading,
    error,
    cmsUrl,
  } = useAxiosGet(urlManagement);

  console.log(error);

  const ManagmentToRender = isLoading ? (
    <div className="loader"></div>
  ) : (
    managment
      .sort((a, b) => a.id - b.id)
      .map((item, i) => {
        const imgManager = item.zdjecie_profilowe[0]
          ? cmsUrl + item.zdjecie_profilowe[0].url
          : "/assets/img/budowlani-mis.png";

        return (
          <ManagementCard
            key={i}
            name={item.imie_nazwisko}
            imgManager={imgManager}
            position={item.stanowisko}
            contact={item.nr_kontaktowy}
            mail={item.e_mail}
            text={item.opis}
          />
        );
      })
  );

  return (
    <>
      <HeaderPages src={"/assets/img/header-pages/news.jpg"} />
      <section className="managment-section-p">
        <div className="row-width">
          <h2>Zarząd</h2>
          <div className="managment-container">
            {/* <img
              className="managment-img"
              src="/assets/temporal/management-team.jpg"
              alt="zarząd"
            /> */}
            {ManagmentToRender}
          </div>
        </div>
      </section>
    </>
  );
};
export default Management;
