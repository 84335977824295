import React, { Component } from "react";
import "slick-carousel/slick/slick.scss";
import "slick-carousel/slick/slick-theme.scss";

import "./_partnerSlider.scss";
// import img from "../Sponsors/img";

import Slider from "react-slick";

export default class AutoPlay extends Component {
  render() {
    const settings = {
      dots: false,
      infinite: true,
      slidesToShow: 5,
      slidesToScroll: 1,
      autoplay: true,
      speed: 1000,
      autoplaySpeed: 3000,
      initialSlide: 0,
      cssEase: "linear",
      arrows: false,

      // centerMode: true,

      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: true,
            // dots: true,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: true,
            // dots: true,
          },
        },
        {
          breakpoint: 440,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 3,
            infinite: true,
            // dots: false,
          },
        },
        {
          breakpoint: 320,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            infinite: true,
            // dots: false,
          },
        },
      ],
    };
    return (
      <div className={this.props.className}>
        <Slider {...settings}>
          {/* <div>
            <img
              cName="img-spon"
              src="/assets/img/sponsors/svg/alveus.svg"
            />
          </div>
          <div>
            <img
              cName="img-spon"
              src="/assets/img/sponsors/svg/wlkpss.png"
            />
          </div>
          <div>
            <img
              cName="img-spon"
              src="/assets/img/sponsors/svg/kopernik.svg"
            />
          </div>
          <div>
            <img
              cName="img-spon"
              src="/assets/icons/partners/partners/mini-market.svg"
            />
          </div>
          <div>
            <img
              cName="img-spon"
              src="/assets/img/sponsors/svg/rosmed.svg"
            />
          </div>
          <div>
            <img
              cName="img-spon"
              src="/assets/icons/partners/partners/trans-bud.svg"
            />
          </div>
          <div>
            <img
              cName="img-spon"
              src="/assets/icons/partners/partners/wulkan-serwis.jpg"
            />
          </div>
          <div>
            <img
              cName="img-spon"
              src="/assets/img/sponsors/svg/zborala.svg"
            />
          </div> */}
          <div>
            <img cName="img-spon" src="/assets/img/sponsors/svg/alveus.svg" />
          </div>
          <div>
            <img cName="img-spon" src="/assets/img/sponsors/svg/wlkpss.png" />
          </div>
          <div>
            <img cName="img-spon" src="/assets/img/sponsors/svg/kopernik.svg" />
          </div>
          <div>
            <img
              cName="img-spon"
              src="/assets/icons/partners/partners/mini-market.svg"
            />
          </div>
          <div>
            <img cName="img-spon" src="/assets/img/sponsors/svg/rosmed.svg" />
          </div>
          <div>
            <img
              cName="img-spon"
              src="/assets/icons/partners/partners/trans-bud.svg"
            />
          </div>
          <div>
            <img
              cName="img-spon"
              src="/assets/icons/partners/partners/wulkan-serwis.jpg"
            />
          </div>
          <div>
            <img cName="img-spon" src="/assets/img/sponsors/svg/zborala.svg" />
          </div>
        </Slider>
      </div>
    );
  }
}
