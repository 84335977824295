export const SocialMedia = [{
        id: 1,
        name: 'facebook',
        url: 'https://pl-pl.facebook.com/budowlaniCalusNT/'
    },
    {
        id: 2,
        name: 'instagram',
        url: 'https://www.instagram.com/budowlani_kucera/'
    },
    // {
    //     id: 3,
    //     name: 'youtube',
    //     url: ''
    // },
]