import React from "react";
import "./_coachCard.scss";
import ReactMarkdown from "react-markdown";

const CoachCard = ({
  id,
  name,
  imgManager,
  alt,
  position,
  contact,
  mail,
  text,
}) => {
  return (
    <>
      <div id={id} className="coach-p-container">
        <div className="coach-item">
          <h3 className="coach-name">{name}</h3>
          <div>
            <img
              src={imgManager}
              alt={alt}
              className="coach-info-data-coverImg"
            />
          </div>
          <div className="coach-item-info">
            {position ? <h3>{position}</h3> : ""}
            {contact ? (
              <a
                itemProp="phone"
                href={`tel:+48${contact.split(" ").join("")}`}
              >
                <div className="coach-info-data">
                  <img
                    src="/assets/icons/telefon.svg"
                    alt="rocznik zawodnika"
                  />

                  <span>{contact}</span>
                </div>
              </a>
            ) : (
              ""
            )}
            {mail ? (
              <a itemProp="email" href={`mailto:${mail}`}>
                <div className="coach-info-data">
                  <img src="/assets/icons/mail.svg" alt="rocznik zawodnika" />
                  <span>{mail}</span>
                </div>
              </a>
            ) : (
              ""
            )}
          </div>
        </div>
        <div className="coach-person-text">
          <ReactMarkdown
            components={{
              // Map `h1` (`# heading`) to use `h2`s.
              h1: "h2",
            }}
          >
            {text}
          </ReactMarkdown>
        </div>
      </div>
    </>
  );
};
export default CoachCard;
