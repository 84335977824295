export const MenuItems = [{
        id: 1,
        side: 'l',
        name: 'o nas',
        url: 'disable',
        hash: "",
        cName: 'nav-link',
        subMenu: [{
                name: 'historia klubu',
                url: '/historia',
            },
            {
                name: 'zasłużeni',
                url: '/zasluzeni',
            },
            {
                name: 'Statystyki',
                url: '/statystyki',
            },
        ]
    },
    {
        id: 2,
        side: 'l',
        name: 'aktualności',
        url: '/aktualnosci',
        cName: 'nav-link',
        subMenu: [],
    },
    {
        id: 3,
        side: 'l',
        name: 'klub',
        url: 'disable',
        cName: 'nav-link',
        subMenu: [{
                name: 'zarząd',
                url: '/zarzad',


            },
            {
                name: 'zawodnicy',
                url: '/zawodnicy',


            },
            {
                name: 'trenerzy',
                url: '/trenerzy',


            },
            {
                name: 'sekcje',
                url: '/sekcje',


            },
            {
                name: 'wolontariusze',
                url: '/wolontariusze',


            },
        ],

    },
    {
        id: 4,
        side: 'r',
        name: 'galeria',
        url: '/galeria',
        cName: 'nav-link',
        subMenu: [],

    },
    {
        id: 5,
        side: 'r',
        name: 'kontakt',
        url: 'disable',
        cName: 'nav-link',
        subMenu: [{
                name: 'skontaktuj się',
                url: '/formularz',
            },
            {
                name: 'nabór do sekcji',
                url: '/nabor-do-sekcji',
            },
            {
                name: 'wesprzyj nas',
                url: '/wesprzyj-nas',
            },
            {
                name: 'siłownia',
                url: '/silownia',
            },
        ],

    },
]