import React, { useState, useEffect, useLayoutEffect, useRef } from "react";
import { NavLink } from "react-router-dom";

import { MenuItems } from "./MenuItems";
import { SocialMedia } from "./SocialMedia";
import "./_navbar.scss";

import { ReactComponent as LogoW } from "./budowlani_kucera-w.svg";
import { ReactComponent as LogoC } from "./budowlani_kucera-c.svg";

function Navbar({ navbarLocation }) {
  const [burger, setBurger] = useState(false);

  const handelMobileClick = (e) => {
    e.preventDefault();
    if (!burger) {
      setBurger(true);
      document.body.classList.add("position-fixed");
    } else if (burger) {
      setBurger(false);
      document.body.classList.remove("position-fixed");
    }
  };

  const handelNavClick = (menu, e) => {
    if (menu === "disable") {
      e.preventDefault();

      e.target.classList.add("sub-menu-show");
    } else {
      setBurger(false);
      document.body.classList.remove("position-fixed");
    }
  };
  const handelSubMenu = (menu, e) => {
    if (menu === "disable" || "kontakt") {
      e.target.offsetParent.classList.toggle("sub-menu-show");
      e.preventDefault();
    } else {
      //
    }
  };

  const [mobile, setMobile] = useState(false);
  const breakpoint = 1140;
  const handleWindowResize = () => {
    if (window.innerWidth < breakpoint) {
      setMobile(true);
    } else {
      setMobile(false);
      setBurger(false);
    }
  };

  const navRef = useRef();

  useEffect(() => {
    let didScroll;
    let lastScrollTop = 0;
    // const deltaDown = 0;
    const deltaUp = 5;
    let navbarHeight;

    const handelMenuHide = () => {
      navbarHeight = navRef.current.offsetHeight;
      didScroll = true;
    };

    setInterval(() => {
      if (didScroll) {
        hasScrolled();
        didScroll = false;
      }
    }, 250);

    const hasScrolled = () => {
      let st = window.scrollY;

      if (Math.abs(lastScrollTop - st) <= deltaUp) return;

      // If they scrolled down and are past the navbar, add class .nav-up.
      // This is necessary so you never see what is "behind" the navbar.
      if (
        st > lastScrollTop &&
        st > navbarHeight + 400 &&
        window.innerWidth < 767
      ) {
        if (!burger) {
          // Scroll Down
          navRef.current.classList.remove("navdown");
          navRef.current.classList.add("navup");
        }
      } else {
        if (Math.abs(lastScrollTop - st) <= deltaUp) return;

        // Scroll Up
        if (st + window.innerHeight < document.documentElement.scrollHeight) {
          navRef.current.classList.remove("navup");
          navRef.current.classList.add("navdown");
        }
      }
      lastScrollTop = st;
    };

    window.addEventListener("scroll", handelMenuHide);
    return () => {
      window.removeEventListener("scroll", handelMenuHide);
    };
  });

  useLayoutEffect(() => {
    window.addEventListener("DOMContentLoaded", handleWindowResize);
    return () => {
      window.removeEventListener("DOMContentLoaded", handleWindowResize);
    };
  }, []);

  useEffect(() => {
    window.addEventListener("resize", handleWindowResize);
    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  const [navbar, setNavbar] = useState(false);
  // let location = navbarLocation;

  useEffect(() => {
    const HandleChagneNavbar = () => {
      // Zmiana menu na stronie główenj
      if (window.scrollY <= 120) {
        setNavbar(false);
      }
      // Zmiana menu na stronie główenj
      // if (window.scrollY >= 300) {
      //   setNavbar(true);
      // }
      // Zmiana menu na podstronach
      // if (location !== "/") {
      //   setNavbar(true);
      // }
      else return setNavbar(true);
    };

    window.addEventListener("scroll", HandleChagneNavbar);
    return () => {
      window.removeEventListener("sroll", HandleChagneNavbar);
    };
  }, []);

  const menuToRenderL = MenuItems.map((menu, i) => {
    if (menu.side === "l") {
      return (
        <li
          key={i}
          className="li-main"
          onClick={(e) => {
            handelSubMenu(menu.url, e);
          }}
        >
          <NavLink
            onClick={(e) => {
              handelNavClick(menu.url, e);
            }}
            activeClassName="link-active"
            exact
            to={{
              pathname: menu.url,

              hash: menu.hash,
              state: { fromDashboard: true },
            }}
          >
            {menu.name}
          </NavLink>
          <ul className="ul-sub-menu">
            {menu.subMenu.map((submenu, i) => {
              return (
                <li key={i}>
                  <NavLink
                    onClick={handelNavClick}
                    activeClassName="link-active"
                    exact
                    to={submenu.url}
                  >
                    {submenu.name}
                  </NavLink>
                </li>
              );
            })}
          </ul>
        </li>
      );
    } else return [];
  });
  const menuToRenderR = MenuItems.map((menu, i) => {
    if (menu.side === "r") {
      return (
        <li
          key={i}
          className="li-main"
          onClick={(e) => {
            handelSubMenu(menu.url, e);
          }}
        >
          <NavLink
            onClick={(e) => {
              handelNavClick(menu.url, e);
            }}
            activeClassName="link-active"
            exact
            to={{
              pathname: menu.url,

              hash: menu.hash,
              state: { fromDashboard: true },
            }}
          >
            {menu.name}
          </NavLink>
          <ul className="ul-sub-menu">
            {menu.subMenu.map((submenu, i) => {
              return (
                <li key={i}>
                  <NavLink
                    onClick={handelNavClick}
                    activeClassName="link-active"
                    exact
                    to={submenu.url}
                  >
                    {submenu.name}
                  </NavLink>
                </li>
              );
            })}
          </ul>
        </li>
      );
    } else return [];
  });

  const SocialMediaToRender = SocialMedia.map((social, i) => {
    if (!navbar && !burger)
      return (
        <li key={i}>
          <a href={social.url} target="_blank" rel="noopener noreferrer">
            <img
              className="social-img"
              src={`/assets/icons/${social.name}-w.svg`}
              alt="social-media"
            />
          </a>
        </li>
      );
    if (navbar || burger)
      return (
        <li key={i}>
          <a href={social.url} target="_blank" rel="noopener noreferrer">
            <img
              className="social-img"
              src={`/assets/icons/${social.name}-r.svg`}
              alt="social-media"
            />{" "}
          </a>
        </li>
      );
    else return [];
  });

  // ${navbar ? "links-mobile-condensed" : "links-mobile-mobile"} to usunąłem z 284 lini

  return (
    <nav
      ref={navRef}
      className={`${
        navbar ? "site-navbar-condensed site-navbar-opacity" : "site-navbar"
      } ${burger ? "site-navbar-mobile" : ""}`}
    >
      <div className={burger ? "mobile-down" : "noactive"}></div>
      <div className={burger ? "logo" : "noactive"}>
        <NavLink onClick={handelNavClick} to="/">
          <LogoC className="img" alt=" logo" />
        </NavLink>
      </div>
      <div
        className={`${mobile ? "links-mobile" : "links"} ${
          burger ? "noactive" : ""
        } `}
      >
        <ul className="menu-left"> {menuToRenderL} </ul>

        <div className={navbar ? "logo" : "noactive"}>
          <NavLink onClick={handelNavClick} to="/">
            <LogoC className="img" alt=" logo" />
          </NavLink>
        </div>
        <div
          className={`${navbar ? "noactive" : "logo"} ${
            burger ? "noactive" : "logo"
          }`}
        >
          <NavLink onClick={handelNavClick} to="/">
            <LogoW className="img" alt=" logo" />
          </NavLink>
        </div>
        <ul>
          {menuToRenderR}

          <ul className="social-media"> {SocialMediaToRender} </ul>
        </ul>
      </div>
      <div
        className={`${mobile ? "mobile-menu" : "noactive"} ${
          burger ? "isopen" : ""
        }`}
        onClick={handelMobileClick}
      >
        <span></span>
        <span></span>
        <span></span>
      </div>
    </nav>
  );
}
export default Navbar;
