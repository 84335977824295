import React from "react";
import "./_history.scss";
import HeaderPages from "../components/Header/HeaderPages";

const History = () => {
  return (
    <>
      <HeaderPages src={"/assets/img/header-pages/news.jpg"} />
      <section className="history-section-p">
        <div className="row-width">
          <h2>Historia klubu</h2>
          <p>
            Początki Ludowego Klubu Sportowego „Budowlani-Całus” sięgają wiosny
            1983 roku, wówczas odbyła się liga piłkarska nowotomyskich zakładów
            pracy. W rozgrywkach zwyciężyła drużyna Fabryki Narzędzi
            Chirurgicznych „CHIFA”, pokonując w finale Zakład
            Budowlano-Montażowy Samopomoc Chłopska. Po tym turnieju zawodnicy
            reprezentujący ZBM postanowili założyć klub piłkarski. Przekonali do
            swojego pomysłu dyrektora zakładu Henryka Królika i wspólnie zajęli
            się niezbędnymi procedurami organizacyjnymi.
          </p>
          <p>
            Nowotomyski ZBM należał do Wojewódzkiego Związku Gminnych
            Spółdzielni Samopomoc Chłopska (WZGS) z siedzibą w Poznaniu na Placu
            Wolności 18, uzyskał on zgodę przełożonych z Poznania na rozpoczęcie
            oficjalnej działalności. WZGS statutowo był zobowiązany do
            wspierania, poprzez comiesięczne składki, działalności Wojewódzkiej
            Rady Ludowych Zespołów Sportowych. Warunkiem utworzenia drużyny było
            wstąpienie w szeregi Zrzeszenia Ludowych Zespołów Sportowych.
          </p>
          <p>
            Spotkanie założycielskie Ludowego Zespołu Sportowego odbyło się w
            świetlicy Zakładu Budowlano-Montażowego przy ulicy Kolejowej 40a,
            byli na nim obecni pracownicy zakładu. Pierwszym przewodniczącym LZS
            wybrany został Mirosław Wojtkowiak, sekretarzem została Janina
            Sobczak, a w skład rady koła weszli jeszcze Jarosław Kucharski oraz
            Jerzy Stachowiak. Za nazwę nowopowstałego ogniwa LZS przyjęto
            „Budowlani” z siedzibą i adresem do korespondencji: ul. Kolejowa
            40a, 64-300 Nowy Tomyśl.
          </p>
          <p>
            Po dokonaniu wszystkich procedur drużyna piłki nożnej LZS
            „Budowlani” została zgłoszona do rozgrywek Poznańskiego Okręgowego
            Związku Piłki Nożnej i latem 1983 roku zaczęła grać w grupie III
            klasy „C”. Drużyna trenowała na gruntach należących do Zakładów
            Wikliniarskich przy ul. Wypoczynkowej, z kolei mecze rozgrywała na
            stadionie należącym do NOSiR przy placu Chopina. Podjęto próbę
            budowy boiska w kompleksie Nowotomyskiego Parku Kultury i
            Wypoczynku, jednak okazała się ona daremna.
          </p>
          <p>
            W 1984 roku nastąpiła zmiana przewodniczącego LZS, funkcję tę objął
            i sprawował do 1989 roku, Jerzy Stachowiak, dotychczasowy
            przewodniczący Mirosław Wojtkowiak został kierownikiem drużyny piłki
            nożnej.
          </p>
          <p>
            Działania „Budowlanych” w dużym stopniu ukierunkowane były na pracę
            z młodzieżą, traktowano ją priorytetowo. Już w 1987 roku sekcja
            piłki nożnej zgłosiła do poznańskiego OZPN łącznie cztery drużyny
            młodzieżowe, podczas gdy inny nowotomyski klub „Polonia” tylko trzy.
            Także z myślą o dzieciach i młodzieży na początku 1987 roku doszło
            do znacznego poszerzenia zakresu sportowej działalności
            „Budowlanych”. Powołana została, uczestnicząca z sukcesami w
            lokalnych rozgrywkach, sekcja tenisa stołowego, Ryszard Karpowicz
            natomiast zainicjował działalność sekcji zapasów w stylu klasycznym
            (później dołączył do niego Piotr Kowalski), a Marek Polcyn założył
            sekcję piłki siatkowej, dwa lata później, w 1989 roku Jerzy Nowak
            zainaugurował działalność sekcji podnoszenia ciężarów. Powstawanie
            kolejnych sekcji było możliwe dzięki przychylności i zaangażowaniu
            dyrekcji ZBM.
          </p>
          <p>
            Przedstawiciele poszczególnych dyscyplin funkcjonujących w
            „Budowlanych” odbywali treningi w różnych miejscach. Siatkarze
            ćwiczyli w Liceum Ogólnokształcącym im. Mikołaja Kopernika,
            zapaśnicy i sztangiści w budynku po Starym Domu Kultury przy ul.
            Marcelego Nowotki (obecna ul. Witosa), tenisiści stołowi natomiast
            trenowali w świetlicy ZBM przy ul. Kolejowej 40a.
          </p>
          <p>
            Sekcje finansowane były przez ZBM, pieniądze przeznaczano na opłaty
            związkowe, ubezpieczenie, zakup sprzętu sportowego, organizację
            imprez i obozów sportowych, a także na zapewnienie transportu oraz
            zabezpieczenie innych potrzeb. „Budowlani” mogli również liczyć na
            wsparcie WZ LZS w Poznaniu. Trenerzy każdej z sekcji, będący ich
            nieformalnymi przewodniczącymi, starali się pozyskiwać dodatkowe
            środki na poprawę bieżącej działalności.
          </p>
          <p>
            Od 1987 roku aktywnym działaczem „Budowlanych” był Zbigniew Kroma,
            początkowo pełnił on rolę wiceprzewodniczącego ds. zapasów w stylu
            klasycznym, a w 1989 roku zastąpił Jerzego Stachowiaka na stanowisku
            przewodniczącego LZS.
          </p>
          <p>
            Działalność Ludowego Zespołu Sportowego „Budowlani” Nowy Tomyśl
            doceniono w 1989 roku, gdy został on uznany najlepszym ogniwem LZS w
            województwie poznańskim. Z tej okazji do Nowego Tomyśla przyjechali
            m.in. przedstawiciele Rady Wojewódzkiej Zrzeszenia LZS oraz Urzędu
            Wojewódzkiego. Było to ogromne wyróżnienie, bowiem w tym okresie w
            Wojewódzkim Zrzeszeniu LZS działało 439 kół.
          </p>
          <p>
            Dynamiczny rozwój „Budowlanych” i jego funkcjonowanie spowodowało
            potrzebę przeorganizowania dotychczasowego koła LZS w Ludowy Klubu
            Sportowy „Budowlani” (LKS). W czwartek 27 grudnia 1990 roku o
            godzinie 17.00 w stołówce pracowniczej Spółdzielni Pracy przy ul.
            Kolejowej 40a odbyło się zebranie założycielskie Klubu Sportowego
            utworzonego z dotychczas działającego w Nowym Tomyślu LZS
            „Budowlani”.
          </p>
          <p>
            W spotkaniu udział wzięli: Wojciech Czajka, Adam Dura, Henryk
            Fabian, Henryk Intek, Dariusz Jendruch, Przemysław Jendruch, Piotr
            Kowalski, Ryszard Kliszewski, Zbigniew Kroma, Henryk Królik, Marian
            Miałkas, Karol Nawrocki, Jerzy Nowak, Marek Polcyn, Mirosław
            Połomka, Jerzy Stachowiak, Mieczysław Wojtkowiak oraz Mirosław
            Wojtkowiak. Zebraniu przewodniczył Zbigniew Kroma, a protokolantem
            był Jerzy Stachowiak.
          </p>
          <p>
            Nowopowstały klub przejął w całości działalność poszczególnych
            sekcji sportowych dotychczasowego Ludowego Zespołu Sportowego
            „Budowlani” wraz z zawodnikami, trenerami i instruktorami.
            Jednocześnie założyciele LKS „Budowlani” postanowili, że również
            majątek w postaci sprzętu sportowego przejdzie nieodpłatnie, z
            przeprowadzeniem inwentaryzacji i przejęciem na stan wyposażania, z
            LZS do klubu i jego poszczególnych sekcji. Środki pieniężne
            dotychczasowego LZS zdeponowane na jego koncie w Banku Spółdzielczym
            przekazane zostały na konto LKS w tym samym banku.
          </p>
          <p>
            Ustalono także, że LKS będzie prowadził działalność sportową,
            rekreacyjną, turystyczną i wychowawczą w oparciu o przyjęty statut.
            Członkiem „Budowlanych” mógł zostać każdy po złożeniu stosownej
            deklaracji i opłaceniu składki członkowskiej.
          </p>
          <p>
            LKS „Budowlani” został członkiem Wojewódzkiego Zrzeszenia LZS w
            Poznaniu oraz Rady Głównej LZS w Warszawie. Funkcję prezesa klubu
            objął Zbigniew Kroma, wiceprezesami zostali: Piotr Kowalski (ds.
            zapasów w stylu klasycznym), Jerzy Nowak (podnoszenie ciężarów),
            Marek Polcyn (piłka siatkowa) oraz Jerzy Stachowiak (piłka nożna).
            Później Zbigniewa Kroma na stanowisku prezesa zmienił Jerzy
            Stachowiak.
          </p>
          <p>
            W dniu 21 stycznia 1991 roku LKS „Budowlani” Nowy Tomyśl został
            wpisany do rejestru stowarzyszeń kultury fizycznej i ich związków
            Wydziału Kultury i Sportu Urzędu Wojewódzkiego w Poznaniu pod
            numerem 145. Od tego momentu klub, funkcjonując jako stowarzyszenie,
            mógł ubiegać się o dotacje na swoją działalność statutową.
          </p>
          <p>
            Przedstawiciele wszystkich sekcji, oprócz tenisa stołowego, odnosili
            sukcesy na szczeblu wojewódzkim, a poszczególni sportowcy byli
            powoływani do kadr okręgu i brali udział w obozach centralnych.
          </p>
          <p>
            Reformy, jakie zaszły w Polsce w okresie zmian ustrojowych od 1989
            roku, dotknęły również LKS „Budowlanych”. Zakład Budowlano-Montażowy
            Samopomoc Chłopska, który przez wszystkie lata finansował klub uległ
            przeobrażeniom i nie był już w stanie wspomagać działalności
            sportowej. W związku z postępującą reorganizacją, a następnie
            sprzedażą ZBM WZGS „SCh” w Nowym Tomyślu wycofał się z finansowania
            LKS „Budowlani”. Kolejne sekcje kończyły swoją działalność –
            najpierw funkcjonować przestała drużyna tenisa stołowego (1991),
            następnie zapasy zostały filią KS „Sobieski” Poznań (1991), a
            miejsce piłkarzy „Budowlanych” w IV lidze przejął LKS „Pogoń”
            Lwówek. W dalszej fazie sekcje piłki siatkowej i zapasów zostały
            wcielone do Międzyszkolnego Ośrodka Sportu w Nowym Tomyślu. Jedyną
            dyscypliną sportową, która nadal działała w LKS „Budowlani”, jednak
            już bez wsparcia ZBM, było podnoszenie ciężarów.
          </p>
          <p>
            Przeobrażenia nastąpiły również we władzach klubu. W 1992 roku
            doszło do zmian personalnych, z powodów zdrowotnych na kilka
            miesięcy z funkcji prezesa zrezygnował Zbigniew Kroma, w tym czasie
            jego obowiązki przejął Jerzy Stachowiak.{" "}
          </p>
          <p>
            W kolejnych latach zarząd jednosekcyjnego klubu działał w składzie:
            prezes – Zbigniew Kroma (do 1995 roku), wiceprezes ds. ponoszenia
            ciężarów – Jerzy Nowak i kierownik sekcji podnoszenia ciężarów –
            Piotr Nowak. Po śmierci Zbigniewa Kromy prezesem LKS „Budowlani”
            został Jerzy Nowak pełnił on tę funkcję w latach 1995-2000.
          </p>
          <p>
            W drugiej połowie lat 90. instruktorem w sekcji podnoszenia ciężarów
            został Konrad Stachowiak, był nim do 2004 roku, w tym czasie był on
            także wiceprezesem ds. podnoszenia ciężarów, trenerem drużyn piłki
            siatkowej, występujących pod nazwą „Kangur-Budowlani”, oraz
            instruktorem podsekcji podnoszenia ciężarów w ZSR w Starym Tomyślu
            utworzonej przez Jerzego Nowaka za zgodą dyrektora tej szkoły
            Bogumiła Surkonta.
          </p>
          <p>
            W czerwcu 1998 roku władze klubu zostały poproszone o opuszczenie
            budynku byłego Domu Kultury położonego na działce nr 1580 w Nowym
            Tomyślu przy ul. Witosa. Dzięki uprzejmości właścicielki posesji
            pani Marii Kańduły LKS „Budowlani” korzystali z lokalu do 31
            sierpnia 1999 roku. Od 1 września tego roku klub zawarł umowę na
            wynajem pomieszczenia byłego magazynu o powierzchni 55 m2 z
            warsztatami Zespołu Szkół Zawodowych im. Stanisława Staszica w Nowym
            Tomyślu przy ul. E. Sczanieckiej 1.{" "}
          </p>
          <p>
            Pod koniec lat 90. LKS „Budowalni” brali udział we współzawodnictwie
            sportowym dzieci i młodzieży, w zależności od liczby punktów
            uzyskanych przez zawodników w tej rywalizacji przyznawane były
            następnie środki pieniężne. Głównym źródłem finansowania klubu były
            w tym czasie dotacje z RG Z LZS w Nowym Tomyślu w kwocie 29.700 zł
            oraz środki uzyskane od sponsorów w wysokości 4.000 zł. Najwięcej
            kosztów stanowiły wydatki administracyjne, organizacja zgrupowań,
            obozów, obozów dochodzących oraz realizacja kalendarza imprez, w
            dalszej kolejności pokrywane były koszty związane z utrzymaniem
            obiektów oraz wynagrodzeniami dla prowadzących zajęcia. Klub miał
            doświadczoną kadrę i zabezpieczenie sportowe.
          </p>
          <p>
            Brak płynności finansowej w latach 1998-1999 spowodował, iż
            rozważano możliwość połączenia się z KS „Promień” Opalenica.
          </p>
          <p>
            W następstwie reformy administracyjnej w Polsce z 1999 roku w marcu
            tego roku zawiązało się Wielkopolskie Zrzeszenie Ludowych Zespołów
            Sportowych, które zajęło miejsce dotychczas funkcjonującego
            Wojewódzkiego Zrzeszenia LZS w Poznaniu i zachęcało kluby do
            wstąpienia w swoje nowopowstałe struktury. LKS „Budowlani” zgłosił
            swój akces do Wlkp. Z LZS i został przyjęty w poczet członków
            prawnych stowarzyszenia. Nowotomyskiemu klubowi na I Walny Zjazd
            Delegatów Wlkp. Z LZS przydzielono, zgodnie z Uchwalą Zebrania
            Założycielskiego, trzy mandaty.
          </p>
          <p>
            W roku 2000 dotychczasowy prezes i trener Jerzy Nowak po 13 latach
            pracy szkoleniowej przestał pełnić dotychczasowe funkcje.
          </p>
          <p>
            W czerwcu 2001 roku zakończono wszystkie procedury związane z
            aktualizacją danych oraz spełniono wszelkie wymogi dotyczące
            rejestracji klubu według nowych zasad w Sądzie Rejonowym XXI
            Wydziału Gospodarczego Krajowego Rejestru Sądowego w Poznaniu pod
            numerem 0000036358. W związku z tym wydarzeniem powołano Komitet
            Założycielski LKS „Budowlani”, jego członkami zostali: Piotr Nowak,
            Jerzy Nowak, Jan Marzyński, Jerzy Walecki, Jerzy Mleczak, Mateusz
            Kurzawski, a także Eugeniusz Wójcik. Od tej chwili klub posiadał
            osobowość prawną i był wpisany w rejestrze stowarzyszeń.
          </p>
          <p>
            Na początku XXI wieku w klubie ponownie powstała sekcja piłki
            siatkowej. Jej reaktywacja nastąpiła za sprawą nowego prezesa
            „Budowlanych” Jarosława Mleczaka oraz innych pasjonatów tej
            dyscypliny sportowej działających w klubie i środowisku lokalnym.
          </p>
          <p>
            Zarząd klubu systematycznie monitował do organów samorządu
            terytorialnego potrzebę zmiany lokalu, coraz więcej było bowiem
            młodzieży chętnej do uprawiania sportu. Jednak metraż wynajmowanego
            lokalu, jego niewielka przestrzeń i brak sanitariatów znacznie
            ograniczały możliwości szkoleniowe oraz szanse dalszego rozwoju. Na
            zakres i poziom kształcenia istotny wpływ miało również zredukowanie
            o 50% dotacji z Urzędu Miasta i Gminy na działalność statutową
            klubu. „Budowlani” zwracali się więc do jednostek administracyjnych,
            a także instytucji i podmiotów prywatnych o dofinansowanie i
            sponsorowanie sekcji.
          </p>
          <p>
            W 2002 roku dotacje z UMiG w Nowym Tomyślu zmniejszyły się o kolejne
            60%, spowodowało to znaczne problemy z funkcjonowaniem klubu, gdyż
            przychody nie pokrywały nawet jego podstawowych potrzeb. W 2003 roku
            sekcja podnoszenia ciężarów została dofinansowana z budżetu Urzędu
            Gminy i Miasta Nowy Tomyśl w kwocie 9000 zł.
          </p>
          <p>
            Mimo kłopotów finansowych w połowie pierwszej dekady XXI wieku klub
            mógł pochwalić się dobrze wyposażonym zapleczem sprzętowym,
            ciężarowcy mieli do dyspozycji: atlas do ćwiczeń stacjonarnych, 11
            gryfów, ponad 200 odważników o masie 1,25 – 35 kg, sztangielki,
            gruszki, pasy ciężarowe oraz kostiumy sportowe. Ilość sprzętu
            umożliwiała realizację procesu treningowego.
          </p>
          <p>
            W tym okresie największe dochody „Budowlanych” pochodziły od
            sponsorów prywatnych (stanowiły one ponad 60% dotacji), klub ściśle
            współpracował z Ośrodkiem Oświaty i Sportu w Nowym Tomyślu. Wszelkie
            rozliczenia środków przyznawanych przez UMiG dokonywane były właśnie
            przez tą organizację.
          </p>
          <p>
            Kolejne lata przyniosły znaczące dla rozwoju klubu powroty byłych
            zawodników sekcji podnoszenia ciężarów. W 2004 roku do Nowego
            Tomyśla wrócił Marcin Lampe, który uzyskał kwalifikacje
            instruktorskie i zajął się szkoleniem sztangistów. Dwa lata później
            „Budowlanych” zaczął wspierać olimpijczyk Paweł Najdek, który w 2008
            roku formalnie stanął na czele zarządu klubu. Razem z nim władze
            „Budowlanych” tworzyli: wiceprezes – Łukasz Trębacz oraz członkowie
            – Piotr Nowak, Jerzy Nowak, Marcin Lampe, Mateusz Kurzawski,
            Bartłomiej Pięta, Mieczysław Tomkowiak i Jarosław Mleczak. Następnie
            doszło do zmian, wiceprezesem został Rafał Górczyński, kierownikiem
            sekcji podnoszenia ciężarów Wojciech Piechowiak, a członkami
            zarządu: Krzysztof Jankowiak, Paweł Bayer oraz Jan Marzyński. W
            skład komisji rewizyjnej wchodzili: Tomasz Wlekły, Sławomir
            Wierzbicki, a także Jerzy Stachowiak. Księgowością w klubie
            zajmowała się Grażyna Pięta, a następnie Barbara Starzak.
          </p>
          <p>
            LKS „Budowlani” utworzyli w 2006 roku podsekcję podnoszenia ciężarów
            funkcjonującą przy Gimnazjum im. Powstańców Wielkopolskich w Lwówku.
            Trenerem został tam Marcin Lampe, nowotomyski klub przekazał na
            potrzeby treningowe sprzęt do podnoszenia ciężarów.
          </p>
          <p>
            W 2010 roku po raz drugi w historii klubu rozwiązana została sekcja
            piłki siatkowej. Sportowcy i trenerzy dotychczas działający w
            „Budowlanych” z dużym powodzeniem funkcjonowali dalej w ramach MUKS
            „Kangur”
          </p>
          <p>
            Nowy zarząd klubu wybrano 27.02.2011 roku, od tamtej chwili
            stanowili go: prezes – Jerzy Nowak, wiceprezes – Marek Świtała,
            skarbnik – Marcin Lampe, kierownik sekcji – Wojciech Piechowiak oraz
            członkowie – Dominik Dyderski, Piotr Urbanowicz i Janusz Wróblewski.
            Skład komisji rewizyjnej ukonstytuował się w składzie:
            przewodniczący – Maciej Strelenok, Tomasz Kudłaszyk oraz Sylwia
            Kupiec.
          </p>
          <p>
            W popularyzowanie działalności „Budowlanych” w środowisku lokalnym i
            podnoszenia ciężarów zaangażował się, z dużymi sukcesami, Robert
            Salwowski, który odpowiadał w klubie za sprawy związane z
            marketingiem.
          </p>
          <p>
            W drugiej dekadzie XXI wieku zawodnicy sekcji podnoszenia ciężarów
            należeli do czołowych ciężarowców Wielkopolski, mieli też na swoim
            koncie wiele osiągnięć w skali ogólnopolskiej. Sytuacja ta
            spowodowała coraz większe zainteresowanie klubem ze strony
            sponsorów, co za tym idzie „Budowlani” mogli liczyć na większe
            dotacje, a zawodnicy na stypendia.
          </p>
          <p>
            Wraz z dokonaniami sportowymi „Budowlani” zaczęli odnosić także
            sukcesy organizacyjne. Na początku 2010 roku do użytku została
            oddana, mieszcząca się na terenie Zespołu Szkół nr 2 im. Stanisława
            Staszica, sala treningowa, w której klub mógł w bardzo dobrych
            warunkach prowadzić szkolenie zarówno zawodniczek, jak i zawodników.
            Sztangiści trenowali tam sześć lat, komfortowe warunki pozwoliły im
            na znaczny progres sportowy. Dzięki osiąganym wynikom klub uzyskał
            wsparcie na budowę nowej, jeszcze nowocześniejszej sali. Od 15
            stycznia 2016 roku LKS „Budowlani” trenują w Młodzieżowym Centrum
            Podnoszenia Ciężarów i zespole hal sportowych w Nowym Tomyślu
            znajdującym się na osiedlu Północ.
          </p>
          <p>
            W dniu 13 lutego 2016 roku w nowotomyskim hotelu „Kopernik” odbyło
            się Walne Sprawozdawcze Zebranie członków klubu, podczas którego
            uchwalono zmianę dotychczasowej nazwy na LKS „Budowlani-Całus”. Nowa
            nazwa wiązała się z decyzją o sponsorowaniu klubu, jaką podjął
            przedsiębiorca Piotr Całus. Jednocześnie zmienił się skład zarządu,
            w miejsce Dominika Dyderskiego, który już wcześniej zakończył
            działalność w klubie, powołany został Mateusz Całus, objął on także
            stanowisko wiceprezesa klubu.
          </p>
          <p>
            LKS „Budowlani-Całus” jest organizatorem wielu imprez rangi
            mistrzowskiej szczebla wojewódzkiego i krajowego. W Nowym Tomyślu
            odbyły się Mistrzostwa Polski LZS do lat 17 (2010) i Mistrzostwa
            Okręgu Juniorów i Juniorek do lat 17 (24.09.2011), corocznie też
            odbywa się Memoriał im. Mariana Głuszka (po raz pierwszy w 2010
            roku). Nowotomyski klub dwukrotnie był także gospodarzem
            Indywidualnego Pucharu Polski Kobiet (27.02.2016 i 15.09.2018) oraz
            raz Indywidualnego Pucharu Polski Mężczyzn (9.11.2019). W dniach
            10-17 września 2016 roku w Nowym Tomyślu odbyły się Mistrzostwa
            Europy Juniorów i Juniorek do lat 15 i 17, których „Budowlani-Całus”
            byli współorganizatorem. Każdorazowo zawody Indywidualnego Pucharu
            Polski były transmitowane na żywo w TVP Sport.
          </p>
          <p>
            Od 2016 roku funkcjonuje przy LKS „Budowlani-Całus” Ośrodek
            Szkolenia Sportowego Młodzieży PZPC finansowany przez ministerstwo
            sportu i turystyki. Prowadzą go trenerzy związani z nowotomyskim
            klubem, a trenują w nim najzdolniejsi zawodnicy do 23 roku w Polsce.
            Obecnie jest to siedmiu wychowanków LKS „Budowlani”.
          </p>
          <p>
            W 2018 roku LKS „Budowlani-Całus” uzyskując 212 punktów, po raz
            pierwszy zwyciężył w Polsce wśród klubów podnoszenia ciężarów w
            ministerialnej rywalizacji dzieci i młodzieży.
          </p>
          <p>
            W dniu 14 kwietnia 2019 roku odbyło się Walne Zebranie
            Sprawozdawczo-Wyborcze LKS „Budowlani-Całus”. Uczestników spotkania
            powitał prezes Jerzy Nowak, a spotkaniu przewodniczył Jakub
            Skrzypczak. Sprawozdawczą część przedstawili kolejno prezes oraz
            przewodniczący komisji rewizyjnej. W wystąpieniu Jerzego Nowaka
            podkreślony został progres klubu i jego sukcesy na przestrzeni
            ostatnich czterech lat. Podziękowano trenerom, zawodnikom oraz
            sponsorom, bez których ciągły rozwój byłby niemożliwy. Sprawozdanie
            komisji rewizyjnej zakończyło się wnioskiem o udzielenie
            absolutorium dla ustępujących władz. Wybrano nowy zarząd klubu, w
            skład którego weszli: prezes – Jerzy Nowak, wiceprezesi – Mateusz
            Całus i Arkadiusz Burkowski, skarbnik – Marcin Lampe oraz członkowie
            – Piotr Urbanowicz, Wojciech Piechowiak i Jakub Skrzypczak. Komisję
            rewizyjną nowej kadencji klubu stworzyli: przewodnicząca – Agata
            Mieloszyńska-Nowak oraz członkowie – Tomasz Kudłaszyk i Maciej
            Strelenok.
          </p>
          <p>
            Od czasu, gdy w 1995 roku prezesem „Budowalnych” został Jerzy Nowak,
            aż do 2016 roku, adresem klubowym było miejsce zamieszkania
            kolejnych członków zarządu. Aktualną siedzibą i adresem LKS
            „Budowlani-Całus” jest Osiedle Północ 37 w Nowym Tomyślu.
          </p>
          <p>
            Oprócz wspomnianych podsekcji podnoszenia ciężarów funkcjonujących
            onegdaj w Starym Tomyślu, a następnie w Jastrzębsku Starym (w 2015
            roku) LKS „Budowlani-Całus” szkoli dzieci i młodzież w Wąsowie,
            Lwówku, Michorzewie, Bukowcu, Sątopach, Wytomyślu, Bolewicach,
            Pniewach, Wolsztynie, Borui Kościelnej, Zbąszyniu, a także w
            Poznaniu.
          </p>
          <h2>Powstanie sekcji podnoszenia ciężarów</h2>
          <p>
            Latem 1988 roku absolwent AWF ze specjalizacją – trener podnoszenia
            ciężarów, pracujący na tym stanowisku w KS „Promień” Opalenica i
            jednocześnie jego były zawodnik Jerzy Nowak podjął, z działaczami
            LZS „Budowlani", rozmowy dotyczące utworzenia w Nowym Tomyślu sekcji
            podnoszenia ciężarów. Wpływ na to wydarzenie miał fakt, iż od
            września tego roku uczniem Liceum Ogólnokształcącego w Nowym Tomyślu
            został Paweł Najdek, jeden z najbardziej perspektywicznych
            sztangistów „Promienia”. Utworzenie sekcji oznaczałoby dla trenera i
            zawodnika brak konieczności dojeżdżania do Opalenicy.
          </p>
          <p>
            Rozmowy okazały się owocne i już w pierwszym kwartale 1989 roku w
            „Budowlanych” została powołana do życia sekcja podnoszenia ciężarów,
            sprawami organizacyjnymi zajął się Jerzy Nowak, a kierownikiem
            sekcji został Zbigniew Kroma. Treningi zaczęły odbywać się w
            przygotowywanej od marca tego roku sali w "Starym Nowotomyskim Domu
            Kultury" przy ówczesnej ul. Marcelego Nowotki (dzisiejszego
            Wincentego Witosa).
          </p>
          <p>
            Przez kilka pierwszych zajęć w klubie przewinęło się wiele osób,
            część z nich już wcześniej pomagała w pracach przygotowawczych,
            takich jak budowa pomostów. Na luty planowano zorganizowanie
            turnieju, który nie doszedł jednak do skutku.
          </p>
          <p>
            Wśród pierwszych trenujących byli: Robert Krysman, Sławomir
            Weselski, Grzegorz Kucz, Przemysław Kuster, Tomasz Spychała, Paweł
            Brzozowski, Krzysztof Beder, Krzysztof Stelmaszyk, Krzysztof Tobys,
            Grzegorz Czyżewski, Grzegorz Tarka, Grzegorz Kasprzak, Marek
            Białkowski, Robert Czyż, Paweł Woźniak, Piotr Parniewicz,
            Włodzimierz Łyżwa, Tomasz Pakuła, Andrzej Kucz, J. Geisler, M.
            Bombelek, A. Kluczyński, J. Duszak, M. Kluczyński, L. Paluch, S.
            Utmańczyk, T. Gucia, K. Patek, P. Freier, D. Pawlik i M. Zieliński.
            Wielu z nich odbyło zaledwie kilka treningów, niektórzy dotrwali do
            pierwszego sprawdzianu, który odbył się w klubie w kwietniu, a
            wzięli w nim udział: R. Czyż, G. Kucz, G. Czyżewski, P. Parniewicz,
            M. Zieliński, P. Woźniak, K. Beder, P. Kuster, K. Stelmaszyk, D.
            Drąg, K. Tobys, R. Krysman, A. Kucz i P. Freier.
          </p>
          <p>
            30 kwietnia sztangiści „Budowlanych” wzięli udział w Wojewódzkiej
            Spartakiadzie Młodzieży do 18 i 20 lat, która odbyła się w
            Opalenicy. W zawodach wzięli udział: Krzysztof Tobys, Robert
            Krysman, Andrzej Kucz, Krzysztof Beder, Dariusz Drąg, Janusz
            Wróblewski i Przemysław Kuster, dwaj ostatni zdobyli pierwsze w
            historii sekcji mistrzostwa województwa. P. Kuster został niebawem
            mistrzem makroregionu, a J. Wróblewskiego powołano do kadry
            makroregionu na zgrupowanie w Koszalinie.
          </p>
          <p>
            Jeszcze w marcu 1989 roku Paweł Najdek, dojeżdżający w tym czasie
            wraz z trenerem J. Nowakiem dwa razy w tygodniu na treningi do
            Opalenicy, zwrócił się z prośbą o zmianę barw klubowych z
            „Promienia” na „Budowlanych”, otrzymał negatywną odpowiedź. 1
            września tego roku został on jednak, na swoją prośbę, wypożyczony do
            nowotomyskiego klubu na okres roku.
          </p>
          <p>
            W lipcu sztangista ten uczestniczył w XVI OSM, która odbyła się w
            Kielcach, podczas sportowych zmagań, będący na wypożyczeniu do
            „Budowlanych”, zawodnik wywalczył brązowy medal. W listopadzie
            (24-26) podczas MP Z LZS juniorów do lat 16 i 18 zdobył tytuł
            mistrzowski.
          </p>
          <p>
            We wrześniu 1989 roku „Budowlani” pozyskali z ZSR we Wrześni atlas
            do ćwiczeń siłowych, na którym od października tego roku, zaczęła
            trenować grupa kulturystyczna, cieszyła się ona dużym
            zainteresowaniem, liczyła około 25 członków. Nowotomyscy kulturyści
            ćwiczyli, aby kształtować własną sylwetkę, nie podejmowali
            rywalizacji w zewnętrznych konkursach. Grupa ta była finansowo
            samowystarczalna i nie musiała być wspomagana przez klub. Kulturyści
            skupiali się i sami opłacali korzystanie ze sprzętu i pomieszczeń.
          </p>
          <p>
            W 1990 roku LZS „Budowlani” zgłosili się do rozgrywek ligowych
            podnoszenia ciężarów. Podczas pierwszego rzutu drużynowych MP w
            Gorzowie Wlkp. (07.04.1990) nowotomyski klub był reprezentowany
            przez następujących zawodników: M. Kudłaszyka, M. Gajewskiego, M.
            Mendela, P. Najdka, J. Stroińskiego, K. Bedera i J Wróblewskiego. W
            drugim rzucie w Ostrowie Wlkp., dokąd zawodnicy „Budowlanych” udali
            się samochodem marki nysa, zajęli II miejsce, wystąpili w składzie:
            P. Najdek, J. Wróblewski, J. Stroiński, M. Mendel, M. Kudłaszyk, P.
            Kudłaszyk, M. Białkowski i M. Gajewski. Rywalizowali również „Stal”
            Ostrów Wlkp. i LKS Dobryszyce.
          </p>
          <p>
            W maju 1990 roku podczas finału XVII OSM w Białogardzie Paweł Najdek
            zdobył mistrzostwo Polski, a Janusz Wróblewski uplasował się na VIII
            pozycji. W tym samym roku „Budowlani” byli organizatorami
            Wojewódzkich Mistrzostw Młodzików, spośród startujących w nich
            zawodników gospodarzy swoje kategorie wiekowe zwyciężyli: Jacek
            Pietrzak, Maciej Kudłaszyk, Mirosław Łuszczak, Robert Korbanek,
            Marcin Ciesielczyk, Marek Gajewski, Rafał Białkowski i Piotr
            Słociński. Mariusz Mendel został powołany na obóz organizowany przez
            PZPC w Giżycku.
          </p>
          <p>
            Podczas Makroregionalnych Mistrzostw Młodzików w Trzciance
            (22-23.09.1990) „Budowlanych” reprezentowało 16 zawodników. Z tego
            grona I miejsca zajęli: Jacek Pietrzak, Maciej Kudłaszyk, Mirosław
            Łuszczak, Marcin Ciesielczyk oraz Rafał Białkowski, II lokaty
            należały do Krzysztofa Szefnera i Tomasza Idzikowskiego, natomiast
            na III pozycjach uplasowali się: Piotr Urbanowicz, Błażej Hałas oraz
            Marek Gajewski. Drużynowo zespół z Nowego Tomyśla zajął I miejsce. W
            skład kadry olimpijskiej przygotowującej się do Igrzysk Olimpijskich
            w Barcelonie w 1992 roku wszedł Wojciech Piechowiak, w rezerwie
            pozostał Paweł Najdek. Za zgodą tego zawodnika doszło, na podstawie
            umowy z 24.10.1990, do jego przejścia z KS „Promień” do LZS
            „Budowlani”, w zamian za to nowotomyski klub miał zagwarantować
            pozyskanie instruktora podnoszenia ciężarów dla „Promienia”. Trener
            Jerzy Nowak zobowiązał się więc do prowadzenia dwa razy w miesiącu
            zajęć dla Opaleniczan, w ramach współpracy „Budowlani” mieli też
            umożliwić zawodnikom „Promienia” udział we wspólnych treningach i
            udostępnić sprzęt.
          </p>
          <p>
            W 1991 roku nowotomyska drużyna zdołała wywalczyć awans do II ligi.
            Zawodnicy odnieśli ogólnopolskie sukcesy – Wojciech Piechowiak
            wywalczył II miejsce w MP seniorów w kat. 110 kg (Cetniewo), a
            Maciej Kudłaszyk zajął II miejsce w MP juniorów.
          </p>
          <p>
            W 1992 roku sekcja podnoszenia ciężarów pozostała jedyną jaka
            funkcjonowała w Ludowym Klubie Sportowym „Budowlani”. Działacze
            klubowi zwrócili się do burmistrza Nowego Tomyśla Jerzego Pańczaka o
            wyrażenie zgody na czasowe przekazanie budynku byłego Domu Kultury
            przy ul. Witosa na jego potrzeby, do celów szkoleniowo-treningowych.
            W tym czasie trwały czynności związane z ustalaniem właściciela
            obiektu, klub zaproponował więc jego użytkowanie i eksploatowanie do
            czasu zakończania spraw własnościowych.
          </p>
          <p>
            W okresie 17-28 sierpnia 1993 roku LKS „Budowlani” zorganizowali
            akcję letnią, w prowadzonych w jej ramach zajęciach
            sportowo-rekreacyjnych uczestniczyli: Krzysztof Szymański, Paweł
            Kańduła, Marcin Intek, Marcin Lampe, Marcin Kunachowicz, Maciej
            Steinke, Szymon Łuczkowski, Szymon Polus, Sławomir Wierzbicki,
            Tomasz Daniec, Maciej Wieczorek, Mirosław Manyś, Łukasz Trębacz,
            Paweł Oszejko, Jarosław Listewnik oraz Tomasz Kudłaszyk.
          </p>
          <p>
            Wśród osób odnoszących tryumfy znalazł się również trener Jerzy
            Nowak, startujący w najbardziej prestiżowych zawodach weteranów na
            świecie. Szkoleniowiec zdobył mistrzostwo świata weteranów (Perth,
            Australia 1994) w kategorii do 75 kg oraz do 40 roku życia.
          </p>
          <p>
            Wśród sukcesów odniesionych przez zawodników „Budowlanych” w 1995
            roku było wicemistrzostwo Polski seniorów Pawła Najdka, a także
            zdobycie medali przez Tomasza Kudłaszyka i Marcina Lampe na MP Z LZS
            i w innych zawodach.
          </p>
          <p>
            W 1995 roku trener Jerzy Nowak został prezesem LKS „Budowlani”, był
            to trudny okres w działalności klubu. Po przemianach ustrojowych w
            nowych realiach gospodarczych i społecznych ciężko było znaleźć
            stabilizację finansową. Klub wnioskował do sponsorów i do władz
            miasta o wsparcie, jednak często bez pozytywnych efektów. Sytuacja
            ta odbijała się na wynikach sportowych.
          </p>
          <p>
            Drużyna „Budowlanych” pięła się w krajowej hierarchii klubowej, w
            1991 roku awansowała do II ligi. W latach 1993 i 1995 nowotomyscy
            zawodnicy uzyskiwali promocje do I ligi podnoszenia ciężarów, jednak
            ani za pierwszym, ani za drugim razem nie zdołali się w niej
            utrzymać.
          </p>
          <p>
            Podczas występów w I lidze w latach 1994 i 1996 klub reprezentowali:
            Ireneusz Bielawski, Krzysztof Franc, Tomasz Kudłaszyk, Marcin Lampe,
            Mirosław Łuszczak, Paweł Najdek, Dariusz Pietruszyński oraz Wojciech
            Natusiewicz. Ostatni z zawodników był wypożyczany przez nowotomyski
            klub na ligowe starty z „Budowlanych” Koszalin. Sztangista ten w
            1996 roku był uczestnikiem Igrzysk Olimpijskich w Atlancie.
          </p>
          <p>
            W grudniu 1997 roku klub zorganizował zgrupowanie w Koszalinie. W
            tym czasie zaczęła funkcjonować podsekcja „Budowlanych” w Zespole
            Szkół Rolniczych w Starym Tomyślu. Siłownia na terenie ZSR miała 40
            m2.
          </p>
          <p>
            Szkoleniem centralnym objęci byli Marcin Lampe i Tomasz Kudłaszyk, a
            w kadrze makroregionu znajdowali się: Mariusz Klimko, Emil Mleczak,
            Tomasz Sikorski i Tomasz Strzelecki. W 1997 roku w LKS „Budowlani”
            funkcjonowały 2 grupy młodzieżowe, należało do niego 26 członków, 1
            senior i 1 instruktor. Kierownikiem sekcji był Piotr Nowak.
          </p>
          <p>
            W 1998 roku z nowotomyskiego klubu do CWKS „Legia” Warszawa odszedł
            Paweł Najdek, stało się to wtedy gdy, mimo wielu zabiegów, nie można
            było temu utalentowanemu zawodnikowi zapewnić stypendium, które
            pozwoliłoby mu rozwijać talent sportowy. Po dwóch latach pobytu w
            stolicy Polski wystąpił na Igrzyskach Olimpijskich w Sydney, zajął
            tam VII lokatę. Na kolejnych IO w Atenach w 2004 roku wywalczył VI
            miejsce.
          </p>
          <p>
            W tym czasie oprócz trenera J. Nowaka w klubie pracowało jeszcze
            łącznie trzech szkoleniowców, instruktorów i pomocników podnoszenia
            ciężarów. W lipcu 1998 roku zdołano zorganizować zgrupowanie w
            ośrodku dydaktyczno-szkoleniowym AZS AWF w Olejnicy, w którym
            uczestniczyli: Tomasz Sikorski, Marcin Kulus, Krystian Ozóg,
            Arkadiusz Plewa, Mateusz Szymankiewicz, Krzysztof Szulc, Rafał
            Dzięcioł, Marcin Gawron, Paweł Dzikowski, Łukasz Kulus, Paweł
            Ćwiertnia, Mariusz Małecki, Kamil Jędrzejczak oraz Rafał Koza.
          </p>
          <p>
            Łącznie szkoleniem zostało objętych 28 zawodników, w tym 14 było
            zarejestrowanych w WZPC w Poznaniu. Grupa młodsza, naborowa,
            spotykała się trzy razy w tygodniu, zawodnicy starsi, zaawansowani
            ćwiczyli pięć-sześć razy w tygodniu. LKS nie posiadało własnej bazy
            lokalowej, treningi prowadzone były w pomieszczeniach dzierżawionych
            w ZSZ im. Stanisława Staszica w Nowym Tomyślu (od 1999 roku) oraz w
            siłowni ZSR w Starym Tomyślu.
          </p>
          <p>
            Największymi sukcesami w 1999 roku były: V miejsce Tomasza
            Kudłaszyka w Młodzieżowych Mistrzostwach Polski do lat 23, VI
            miejsce Kamila Mleczaka i X Arkadiusza Plewy w MP do lat 16, IX
            miejsce Michała Nowaka w MP do lat 18 oraz medale podczas
            Makroregionalnych Mistrzostw do lat 18 – Michał Nowak (I miejsce),
            Łukasz Wiesiołek, Paweł Ćwiertnia (II), a także Tomasz Sikorski
            (III) i do lat 16 – Kamil Mleczak (I), Łukasz Wiesiołek (II) i
            Arkadiusz Plewa (III).
          </p>
          <p>
            W 2000 roku klub zorganizował obóz w Koszalinie, w którym udział
            wzięli: Aleksander Tomkowiak, Artur Plewa, Dariusz Pluskota, Dariusz
            Stachowiak, Michał Nowak, Łukasz Wiesiołek i Tomasz Kudłaszyk.
          </p>
          <p>
            Podczas mistrzostw okręgu w Tarcach „Budowlanych” reprezentowali:
            Aleksander Tomkowiak, Artur Plewa, Dariusz Stachowiak, Michał Nowak,
            Łukasz Wiesiołek i Tomasz Cembrowski. Na OOM byli Łukasz Wiesiołek i
            Michał Nowak
          </p>
          <p>
            W sekcji pracowali trener i dwóch instruktorów, prezes Jerzy Nowak
            nawiązał współpracę z ZSR w Trzciance i Starym Tomyślu, prowadził
            szkolenia dla nauczycieli i instruktorów z zakresu podnoszenia
            ciężarów, podczas nich sportowcy korzystali z siłowni i sal, a klub
            dbał o zabezpieczenie wyżywienia i zakwaterowanie.
          </p>
          <p>
            W 2000 roku sztangiści „Budowlanych” wzięli udział w Wielkopolskim
            Turnieju Juniorów w Tarcach, Mistrzostwach Zrzeszenia LZS Kobiet
            Open w Więcborku, Makroregionalnych Mistrzostwach Młodzików do 15-16
            lat w Opalenicy, Mistrzostwach Polski do lat 23 w Siedlcach, a także
            Wielkopolskim Turnieju Młodzików w Opalenicy oraz w Białogardzie. Na
            przełomie stycznia i lutego ośmiu sztangistów wzięło udział w
            zgrupowaniu w internacie w Ośrodku Szkolno-Wychowawczym w
            Koszalinie. W tym roku Łukasz Wiesiołek i Jakub Sawicki otrzymali II
            Kółko Olimpijskie, a Aleksander Tomkowiak I Kółko. Sebastian
            Wlaźlak, Jacek Heliasz i Dawid Herla zajmowali czołowe miejsca w
            turniejach wojewódzkich, a Ł. Wiesiołek zdobył mistrzostwo
            Wielkopolski juniorów do lat 18 w kat. 77 kg i był członkiem kadry
            makroregionu juniorów.
          </p>
          <p>
            W 2000 roku zawodnicy sekcji brali udział łącznie w 12 imprezach o
            randze wojewódzkiej i ogólnopolskiej. Szkoleniem objętych było 28
            zawodników. Zajęcia prowadzone były w trzech grupach:
            naborowo-początkującej, średniozaawansowanej i zaawansowanej.
          </p>
          <p>
            W tych latach sztangiści „Budowlanych” zarejestrowani w WZPC objęci
            byli systematyczną opieką lekarską Wielkopolskiej Przychodni
            Sportowo-Lekarskiej w Poznaniu. Zawodnicy ubezpieczeni byli w ramach
            grupowego ubezpieczenia Wielkopolskiego Stowarzyszenia Sportowego.
            Zajęcia odbywały się od poniedziałku do piątku w godzinach
            17.00-19.00 i w soboty w godzinach 10.00-12.00, w zależności od
            stażu treningowego sportowcy spotykali się trzy-sześć razy w
            tygodniu.
          </p>
          <p>
            Oprócz startów w zawodach sztangiści uczestniczyli także w obozach
            sportowych szkolenia centralnego – pięciu zawodników przebywało na
            zgrupowaniu kadry wojewódzkiej juniorów w Olejnicy, a kolejnych
            sześciu na zgrupowaniu kadry młodzików w Łężeczkach. Znaczącymi
            sukcesami sztangistów „Budowlanych” w tym okresie były:
          </p>
          <ul>
            <li>
              Młodzieżowe MP do lat 23
              <ul>
                <li>VIII Tomasz Kudłaszyk</li>
              </ul>
            </li>
            <li>
              MP do lat 18 (OOM)
              <ul>
                <li>X Michał Nowak</li>
                <li>XI Jakub Sawicki</li>
              </ul>
            </li>
            <li>
              MP Z LZS do lat 20
              <ul>
                <li>VI Łukasz Wiesiołek</li>
                <li>VII Michał Nowak</li>
              </ul>
            </li>
            <li>
              MP Z LZS do lat 18
              <ul>
                <li>VI Łukasz Wiesiołek,</li>
                <li>X Jakub Sawicki</li>
                <li>XII Maciej Strelenok</li>
              </ul>
            </li>
            <li>
              MP Szkół Rolniczych
              <ul>
                <li>IV Dariusz Stachowiak</li>
                <li>V Łukasz Wiesiołek</li>
              </ul>
            </li>
            <li>
              Makroregionalne Mistrzostwa juniorów do lat 18
              <ul>
                <li>I Jakub Sawicki</li>
                <li>I Łukasz Wiesiołek</li>
                <li>III Maciej Strelenok</li>
              </ul>
            </li>
            <li>
              Makroregionalne Mistrzostwa Młodzików do lat 16
              <ul>
                <li>III Aleksander Tomkowiak </li>
                <li>III Jakub Sawicki </li>
                <li>III Maciej Strelenok </li>
                <li>III Marcin Koniński </li>
                <li>V Marcin Kulus </li>
                <li>V Łukasz Wiesiołek</li>
              </ul>
            </li>
          </ul>
          <p>
            Wśród medalistów Mistrzostw Województwa do lat 15 byli: Marcin
            Łodyga, Łukasz Jarosik i Aleksander Tomkowiak
          </p>
          <p>
            Podobnie jak w roku ubiegłym w 2001 roku w zajęciach brało udział 28
            sportowców (27 z powiatu nowotomyskiego i 1 z grodziskiego)
            podzielonych na trzy grupy treningowe. Po każdym sukcesie
            odniesionym przez ciężarowców ukazywała się informacja w prasie
            lokalnej. Warunki lokalowe (związane z bezpieczeństwem prowadzenia
            zajęć) ograniczały szerszy zakres szkolenia. Młodzież uczestniczyła
            w zgrupowaniach, siedmiu zawodników przebywało na zgrupowaniu kadry
            Wojewódzkiej młodzików w Olejnicy, pięciu kadry wojewódzkiej
            juniorów w Łężeczkach i również pięciu kadry LZS w Białogardzie.
            Koszty pobytu na obozach pokrywane były przez uczestników
            zgrupowania, Urząd Marszałkowski Województwa Wielkopolskiego oraz
            LKS „Budowlani”.
          </p>
          <p>Do osiągnięć sekcji w roku szkoleniowym 2001/2002 należą:</p>
          <ul>
            <li>MP do lat 20 </li>
            <ul>
              <li>VIII miejsce Łukasz Wiesiołek </li>
              <li>IX Michał Nowak</li>
            </ul>
            <li>MP do lat 18 (OOM)</li>
            <ul>
              <li>VIII Łukasz Wiesiołek (w 2001 roku) </li>
              <li>XV Jakub Sawicki 2001 </li>
              <li>XVI Jakub Sawicki 2002</li>
              <li>XVII Maciej Strelenok 2002</li>
            </ul>
            <li>MP do lat 16 </li>
            <ul>
              <li>VII Aleksander Tomkowiak 2001</li>
              <li>IX Aleksander Tomkowiak 2002</li>
              <li>XVI Marcin Łodyga 2002</li>
            </ul>
            <li>MP Z LZS do lat 18</li>
            <ul>
              <li>V Łukasz Wiesiołek</li>
              <li>XV Jakub Sawicki</li>
              <li>XI Maciej Strelenok</li>
            </ul>
            <li>MP Z LZS do lat 16</li>
            <ul>
              <li>X Aleksander Tomkowiak 2001</li>
              <li>VI Aleksander Tomkowiak 2002</li>
            </ul>
            <li>MP Szkół Rolniczych</li>
            <ul>
              <li>III Łukasz Wiesiołek</li>
              <li>IV Dariusz Stachowiak</li>
            </ul>
            <li>Makroregionalne Mistrzostwa Juniorów do lat 18</li>
            <ul>
              <li>I Jakub Sawicki</li>
              <li>I Łukasz Wiesiołek</li>
              <li>II Jacek Poznański</li>
              <li>III Maciej Strelenok</li>
              <li>III Michał Konwiński</li>
              <li>V Łukasz Jarosik</li>
            </ul>
            <li>Makroregionalne Mistrzostwa Młodzików do lat 16 </li>
            <ul>
              <li>I Aleksander Tomkowiak</li>
              <li>II Marcin Łodyga</li>
              <li>III Adrian Pszeniczny</li>
              <li>III Jakub Borowiak</li>
              <li>III Łukasz Jarosik</li>
              <li>III Tomasz Cembrowski</li>
              <li>IV Adrian Prętki</li>
              <li>IV Mirosław Miłek</li>
            </ul>
          </ul>
          <p>
            W roku szkoleniowym 2002/2003 treningami objętych było 24
            zawodników, z czego 16 zarejestrowano w PZPC. Wzięli oni udział w 12
            imprezach. Sekcja posiadała seniora – Tomasza Kudłaszyka. Kilku
            sztangistów otrzymało I lub II koło olimpijskie. Do najważniejszych
            wyników sportowych tego okresu należy zaliczyć:
          </p>
          <ul>
            <li>MP do lat 23</li>
            <ul>
              <li>VIII miejsce Tomasz Kudłaszyk</li>
              <li>MP do lat 20</li>
              <li>VIII Łukasz Wiesiołek</li>
              <li>XII Jakub Sawicki</li>
              <li>XIII Maciej Strelenok</li>
            </ul>
            <li>MP do lat 18 (OOM Kielce) </li>
            <ul>
              <li>XI Maciej Strelenok (2002 rok)</li>
              <li>XV Aleksander Tomkowiak (2003)</li>
              <li>XVII Jakub Sawicki (2002)</li>
            </ul>
            <li>MP Z LZS do lat 16 (w 2002 roku)</li>
            <ul>
              <li>VII Aleksander Tomkowiak</li>
              <li>XVI Łukasz Łodyga</li>
            </ul>
            <li>MP Z LZS do lat 17 (w 2003 roku)</li>
            <ul>
              <li>V Aleksander Tomkowiak </li>
              <li>VII Jakub Borowiak</li>
              <li>VIII Łukasz Łodyga</li>
            </ul>
            <li>MP Z LZS do lat 20 (w 2002 roku)</li>
            <ul>
              <li>IV Łukasz Wiesiołek</li>
            </ul>
            <li>Makroregionalne Mistrzostwa Młodzików</li>
            <ul>
              <li>II Marcin Łodyga</li>
              <li>II Aleksander Tomkowiak</li>
              <li>III Adrian Prętkiv</li>
              <li>III Jakub Borowiak</li>
              <li>VII Damian Przybył</li>
              <li>VIII Dariusz Borowiak</li>
              <li>IX Damian Miłek</li>
              <li>X Bartosz Mieleszczuk</li>
            </ul>
          </ul>
          <p>
            Zawodnicy sekcji zdobyli również drużynowo II miejsce podczas
            Turnieju Juniorów w Ostrowie Wlkp. (2003). Najbardziej doświadczony
            sztangista Marcin Lampe kończył wówczas studia oraz kurs
            instruktorski. Grupa zawodników tworzyła bardzo zgrany kolektyw,
            sportowcy „Budowlanych” spotykali się nie tylko podczas treningów,
            ale również w czasie wolnym.
          </p>
          <p>
            W 2003 roku w sekcji trenowało 23 ciężarowców, 16 z nich było
            zarejestrowanych w PZPC. Reprezentanci nowotomyskiego klubu wzięli
            udział w 12 zawodach. Grupę sportowców tworzyli głównie uczniowie
            miejscowego gimnazjum, wiek wszystkich sztangistów to 14-21 lat. W
            klubie trenowało dwóch seniorów. Nadal fatalne były warunki lokalowe
            (klub był garażem przystosowanym na siłownię), sytuacja ta nie
            sprzyjała organizowaniu dużego naboru w związku z czym ograniczano
            go. Ze względów bezpieczeństwa w siłowni mogło przebywać
            jednocześnie nie więcej niż 10 sportowców. Wśród osób wspierających
            klub był Wiesław Pięta.
          </p>
          <p>
            Znaczące wyniki osiągnięte przez zawodników sekcji w sezonie
            2003/2004 przedstawiają się następująco:
          </p>

          <ul>
            <li>MP seniorów w Ciechanowie </li>
            <ul>
              <li>VII miejsce Tomasz Kudłaszyk</li>
              <li>MP do lat 23 w Siedlcach</li>
              <li>IX Łukasz Wiesiołek</li>
            </ul>

            <li>MP do lat 20 w Hrubieszowie</li>
            <ul>
              <li>X Maciej Strelenok</li>
              <li>XI Jakub Sawicki</li>
            </ul>
            <li>OOM Poznań 2004</li>
            <ul>
              <li>X Aleksander Tomkowiak </li>
            </ul>
            <li>MP Z LZS do lat 17 w Biłgoraju</li>
            <ul>
              <li>V Aleksander Tomkowiak</li>
              <li>VII Jakub Borowiak</li>
              <li>VIII Marcin Łodyga</li>
            </ul>
            <li>Makroregionalne Mistrzostwa Młodzików w Ostrowie Wlkp.</li>
            <ul>
              <li>II Bartosz Mieleszczuk</li>
              <li>II Marcin Łodyga</li>
              <li>II Jakub Borowiak</li>
              <li>IV Dariusz Borowiak</li>
              <li>VI Damian Przybył</li>
              <li>IX Michał Kaczmarek</li>
            </ul>
          </ul>

          <p>
            W 2004 roku do Nowego Tomyśla, po ukończeniu studiów, powrócił
            Marcin Lampe, który prowadził zajęcia jako absolwent wychowania
            fizycznego, kurs instruktorski ukończył w 2005 roku. Rozpoczął wtedy
            intensywną pracę z młodzieżą z Nowego Tomyśla. W 2006 roku utworzył
            podsekcję podnoszenia ciężarów w Lwówku, dzieci ćwiczące w tej
            miejscowości spotykały się w pomieszczeniach Ośrodka Sportu i
            Rekreacji, w którym pracował. Zacieśniono współpracę między
            „Budowlanymi” a innymi UKS z terenu powiatu nowotomyskiego i okolic,
            a sportowcy klubu brali udział w większości imprez zaliczanych do
            punktacji sportu i młodzieży. Propagowanie dyscypliny odbywało się
            za pośrednictwem imprez o charakterze wojewódzkim. Udało się
            osiągnąć zainteresowanie młodzieży podnoszeniem ciężarów, zajmowano
            się poprawą sprawności ogólnej i pracowano nad techniką. 20
            zawodników klubu – chłopcy i dziewczęta w wieku 14-21 lat
            realizowali kalendarz WZPC i PZPC, wzięli oni udział w obozie
            sportowym. Sztangiści zdobyli 18 punktów we współzawodnictwie
            sportowym dzieci i młodzieży, zajęli trzy IX miejsca na MMP, zdobyli
            także złoty, srebrny i brązowy medal oraz zajęli sześć VI miejsc na
            MMM. W 2005 roku podczas Mistrzostw Polski LZS do lat 20 w Gorlicach
            Aleksander Tomkowiak zdobył brązowy medal w kat. 77 kg.
          </p>
          <p>
            „Budowlani” otrzymali od PZPC sztangę damską, która zakupiona była
            wcześniej na Mistrzostwa Europy Kobiet i Mężczyzn we Władysławowie
            (2006). Od 2006 roku, za namową działaczy i trenerów LKS
            „Budowlani”, w funkcjonowanie sekcji i klubu angażował się Paweł
            Najdek, wówczas już dwukrotny olimpijczyk, który swoim
            doświadczeniem, ale też osobowością wspierał odbudowę podnoszenia
            ciężarów w Nowym Tomyślu. Kierownikiem sekcji został niebawem
            Wojciech Piechowiak, a za marketing odpowiadał Robert Salwowski.
            Powrót do „Budowlanych” zasłużonych dla tego klubu osób oraz
            pozytywne kreowanie wizerunku podnoszenia ciężarów w środowisku
            lokalnym przyniosło spodziewane efekty.{" "}
          </p>
          <p>
            W 2008 roku Grzegorz Klimek podczas Mistrzostw Polski Juniorek i
            Juniorów do lat 17 w Puławach zajął II miejsce w kategorii +94 kg.
            Był to pierwszy, od 11 lat, medal sztangisty „Budowlanych” w
            zawodach rangi mistrzostw Polski (nie licząc mistrzostw LZS). Od
            tego roku, aż po dzień dzisiejszy, sportowcy z Nowego Tomyśla co
            roku zdobywają medale na imprezach ogólnokrajowych.
          </p>
          <p>
            Do „Budowlanych” z czasem zaczęło zgłaszać się więcej dzieci i
            młodzieży chcącej zostać sztangistami. Sponsorzy coraz chętniej
            wspierali poczynania klubu rozpoznawalnego już nie tylko w okolicy,
            ale również w województwie i pomostach krajowych. Dzięki wsparciu
            materialnemu i finansowemu oraz dotacjom od samorządów
            terytorialnych „Budowlanych” było już stać na organizowanie obozów
            sportowych, najczęściej w Łężeczkach i Olejnicy. Nowotomyska drużyna
            awansowała i wystartowała w rozgrywkach III ligi podnoszenia
            ciężarów.
          </p>
          <p>
            W dniu 15 stycznia 2010 roku została oddana do użytku jedna z
            najnowocześniejszych sal treningowych podnoszenia ciężarów w Polsce,
            mieściła się ona na terenie Zespołu Szkół nr 2 im. Stanisława
            Staszica. Wcześniej w miejscu tym znajdowały się warsztaty szkolne.
            23 lutego odbyły się w Nowym Tomyślu pierwsze, od kilkunastu lat,
            zawody w podnoszeniu ciężarów, były to mistrzostwa Wielkopolski LZS
            do lat 18. Na starcie stanęło ponad 60 zawodniczek i zawodników
            reprezentantów: „Budowlanych”, „Promienia” Opalenica i „Zamku”
            Gołańcz.
          </p>
          <p>
            Od 2010 roku LKS „Budowlani” są organizatorem corocznego Memoriału
            im. Mariana Głuszka (ur. 8.09.1935 roku w Brześciu – zm. 5.07.2007
            roku w Poznaniu) – wieloletniego działacza Rady Wojewódzkiej LZS w
            Poznaniu, Polskiego Związku Lekkiej Atletyki oraz Poznańskiego
            Okręgowego Związku Zapaśniczego i innych organizacji działających na
            rzecz sportu.
          </p>
          <p>
            Po raz pierwszy, od 1995 roku, medal dla „Budowlanych” w rywalizacji
            międzynarodowej wywalczyła w 2011 roku Patrycja Piechowiak, która
            podczas Mistrzostw Europy Juniorów i Juniorek do lat 20 w
            Bukareszcie zajęła III miejsce w kategorii 69 kg. W kolejnym roku
            zawodniczka nowotomyskiego klubu zdobyła swój pierwszy w karierze
            złoty medal na mistrzostwach Polski seniorek. W 2012 roku na podium
            najważniejszych imprez stawali w swoich kategoriach wiekowych
            również: Marta Wesoła, Patryk Wesoły, Szymon Kawa, Radosław Jarnut,
            Krystian Łodyga, Mateusz Krydka oraz Piotr Kudłaszyk.
          </p>
          <p>
            W 2012 roku drużyna „Budowlanych” wywalczyła awans z I miejsca do II
            ligi podnoszenia ciężarów. W październiku tego roku na zaproszenie
            klubu do Nowego Tomyśla przyjechali medaliści olimpijscy Adrian i
            Tomasz Zielińscy. Sportowcy odwiedzili lokalne szkoły i wzięli
            udział w spotkaniach z dziećmi i młodzieżą.
          </p>
          <p>
            27 lutego 2013 roku Nowy Tomyśl odwiedziła minister sportu i
            turystyki Joanna Mucha, podczas tego wydarzenia złożyła ona wizytę
            „Budowlanym”, spotkała się z działaczami i sportowcami, przygląda
            się również treningowi.
          </p>
          <p>
            Piotr Kudłaszyk w 2013 roku podczas rozgrywanych w Kłajpedzie
            Mistrzostw Europy Juniorek i Juniorów do lat 15 zajął III miejsce w
            kategorii wagowej 62 kg. Był to pierwszy, od 18 lat, medal
            wychowanka „Budowlanych” w zawodach tej rangi. W 2014 roku na
            Mistrzostwach Europy Juniorów do lat 17 w Ciechanowie sztangista ten
            zdobył wicemistrzostwo kontynentu.
          </p>
          <p>
            Uroczyście obchodzono 25-lecie istnienia sekcji podnoszenia ciężarów
            w LKS „Budowlani” w 2014 roku. W spotkaniu, które odbyło się
            Nowotomyskim Domu Kultury udział wzięli byli i obecni wówczas
            zawodnicy, trenerzy i działacze klubu, a także zaproszeni goście
            oraz sympatycy. Zasłużeni sportowcy i działacze zostali uhonorowani
            odznakami Zrzeszenia LZS.
          </p>
          <p>
            Niezwykle udane okazały się dla nowotomyskich sztangistów
            Mistrzostwa Europy rozgrywane w szwedzkiej miejscowości Landskrona w
            2015 roku. Piotr Kudłaszyk (startujący w kategorii do lat 17) został
            wicemistrzem Europy, a, debiutujący w kontynentalnym czempionacie,
            Martin Stasik wywalczył brązowy medal (do lat 15).
          </p>
          <p>
            W 2015 roku Piotr Kudłaszyk został pierwszym wśród wychowanków
            „Budowlanych” i drugim zawodnikiem po Pawle Najdku, który w barwach
            nowotomyskiego klubu wywalczył medal na mistrzostwach świata.
            Dokonał tego podczas Mistrzostw Świata Juniorów do lat 17 w Limie
            (Peru), gdzie, startując w kategorii 62 kg, zdobył brązowy medal.
          </p>
          <p>
            Od 15 stycznia 2016 roku LKS „Budowlani” trenują w Młodzieżowym
            Centrum Podnoszenia Ciężarów i zespole hal sportowych w Nowym
            Tomyślu znajdującym się na osiedlu Północ. Nowoczesny kompleks
            stworzony został dzięki zaangażowaniu szeregu ludzi i wsparciu
            jednostek samorządowych oraz Ministerstwa Sportu i Turystyki.
            Powstanie ośrodka osobiście wspomagał aktualny wówczas prezes PZPC
            Szymon Kołecki. Od tego czasu adepci podnoszenia ciężarów mogą
            trenować w profesjonalnych warunkach. Pierwszymi zawodami w nowej
            sali był Indywidualny Puchar Polski Kobiet (27.02.2016).
          </p>
          <p>
            W latach późniejszych klub był też organizatorem ogólnopolskich
            zawodów, m.in. Pucharu Polski Kobiet (2018) oraz Mężczyzn (2019),
            które były transmitowane przez telewizję.{" "}
          </p>
          <p>
            W lutym 2016 roku sponsorem strategicznym „Budowlanych” została
            firma „Całus” Piotra Całusa, członkiem zarządu klubu został
            przedstawiciel przedsiębiorstwa Mateusz Całus, a do klubu dodano
            nazwę sponsora.
          </p>
          <p>
            W 2016 roku po raz pierwszy w historii LKS „Budowlani-Całus”, ale
            również i całego nowotomyskiego sportu, sztangistka z tego miasta
            reprezentowała Polskę na Igrzyskach Olimpijskich, które odbyły się w
            Rio de Janeiro. Patrycja Piechowiak wystartowała w kategorii 69 kg.
            W rwaniu uzyskała 101 kg, z powodu kontuzji zawodniczka nie
            przystąpiła jednak do podrzutu.
          </p>
          <p>
            Drugim ważnym wydarzeniem w historii klubu, jak również i Nowego
            Tomyśla, były Mistrzostwa Europy Juniorów do lat 15 i 17, które
            odbyły się w 2016 roku w Nowym Tomyślu. Współorganizatorem tego
            wielkiego sportowego wydarzenia był LKS „Budowlani-Całus”, co samo w
            sobie już stanowiło duży sukces, kolejne zostały osiągnięte na
            pomoście. Piotr Kudłaszyk i Martin Stasik zdublowali przed
            nowotomyską publicznością swe osiągnięcia sprzed roku zajmując
            odpowiednio II (do lat 17) i III miejsce (do lat 15).
          </p>
          <p>
            Kolejnym sukcesem był awans do Ekstraklasy Drużynowych Mistrzostw
            Polski. 24 września przed nowotomyską publicznością odbył się
            finałowy rzut pierwszej ligi. Drużyna w składzie: Piotr Kudłaszyk,
            Martin Stasik, Radosław Jarnut, Mateusz Miczyński, Aleksander
            Kowalski i Arkadiusz Mocny zdecydowanie pokonała rywali z WKS
            „Śląsk” Wrocław i KS Raszyn.
          </p>
          <p>
            W 2017 roku LKS „Budowlani” zajęli VI lokatę w I lidze. Martin
            Stasik został w Prisztinie (Kosowo) wicemistrzem kontynentu do lat
            17.
          </p>
          <p>
            Podczas rozgrywanych wiosną 2018 roku Mistrzostw Europy Seniorek i
            Seniorów w Bukareszcie brązowy medal wywalczyła Patrycja Piechowiak,
            dla której był to jedyny międzynarodowy medal w kategorii seniorek.
          </p>
          <p>
            Również w 2018 roku Martin Stasik podczas Mistrzostw Polski Seniorów
            w Kobierzycach zdobył tytuł mistrzowski w kategorii 62 kg. Był to
            pierwszy w historii nowotomyskich ciężarów tytuł mistrza Polski
            zdobyty przez wychowanka klubu w kategorii seniorów. W następnym
            dniu sukces ten powtórzył Piotr Kudłaszyk w kategorii 69 kg.
          </p>
          <p>
            W 2018 roku LKS „Budowlani-Całus” Nowy Tomyśl po raz pierwszy zajął
            pierwsze miejsce w Polsce wśród klubów podnoszenia ciężarów w
            ministerialnej rywalizacji dzieci i młodzieży. Drużyna zajęła także
            wysoką IV pozycję w I lidze, sukces ten powtórzyła również w 2019
            roku.
          </p>
          <p>
            W jubileuszowym 30 roku istnienia klubu Paulina Kudłaszyk zdobyła
            wicemistrzostwo Europy do lat 20, a także mistrzostwo Polski
            seniorek. Uczestnikami Mistrzostw Świata Juniorów w Suva (Fidżi)
            byli Piotr Kudłaszyk (XI miejsce) i Martin Stasik (nie ukończył
            zawodów w podrzucie), obaj wywalczyli również MP seniorów. W
            kategoriach młodzieżowych juniorów medale MP zdobywali Julia
            Kowalska i Damian Keller, a wśród juniorów: Klaudia Cicha, Tomasz
            Kowalski, Kacper Przybył i Marta Fliegier.
          </p>
          <p>
            Wśród blisko 370 sztangistów, którzy reprezentowali „Budowlanych” w
            latach 1989-2019, 15 było reprezentantami Polski, należeli do nich:
          </p>

          <ul>
            <li> Jarnut Radosław</li>
            <li>Kawa Szymon</li>
            <li>Kudłaszyk Maciej </li>
            <li>Kudłaszyk Paulina</li>
            <li>Kudłaszyk Piotr</li>
            <li>Kudłaszyk Tomasz</li>
            <li>Lisiak Szymon</li>
            <li>Miczyńska Sylwia</li>
            <li>Najdek Paweł</li>
            <li>Piechowiak Patrycja</li>
            <li>Piechowiak Wojciech</li>
            <li>Przybył Kacper</li>
            <li>Stasik Martin</li>
            <li>Wesoła Marta</li>
            <li>Wielebski Tomasz</li>
          </ul>
          <p>
            Reprezentantem Polski był również wychowanek „Budowlanych” Piotr
            Poniedziałek, jednak wówczas był on już zawodnikiem „Promienia”
            Opalenica.
          </p>
          <p>
            Aktualnie reprezentantami Polski w różnych kategoriach wiekowych są
            następujący zawodnicy „Budowlanych-Całus”: Paulina Kudłaszyk, Piotr
            Kudłaszyk oraz Martin Stasik. W kadrze województwa młodzieżowców
            Wielkopolski – 21-23 lata są: Paulina Kudłaszyk, Katarzyna Lorenc,
            Nicole Miszczykowicz, Wiktoria Tórz, Inez Guderska, Julia Kowalska,
            Karol Cyprowski, Mikołaj Kurkowski oraz Błażej Borowczak. Do kadry
            juniorów województwa Wielkopolskiego – 18-20 lat należą: Emilia
            Bolanowska, Klaudia Cicha, Arkadiusz Mocny, Martin Stasik, Piotr
            Kudłaszyk, Tomasz Kowalski, Adam Kamiński, a także Kacper Perz.
            Kadrę województwa juniorów młodszych – 16-17 lat tworzą: Karina
            Mańczak, Kacper Przybył, Cyprian Boch, Kornelia Dolewa i Szymon
            Lisiak. Do kadry wojewódzkiej młodzików – 13-15 lat powoływani
            zostali: Oliwia Przybył, Martyna Rosolska, Milena Świderska, Alicja
            Zawarta, Paulina Michalska, Daria Gruszkiewicz, Magdalena Kowal,
            Adrian Haładuda, Kacper Dziamski, Adela Szmyt, Andżelika Jasińska,
            Joanna Stryszak, Marta Fliegier, Aleksandra Pakuła, Kacper Lemański,
            Igor Francuzik, Jakub Zimny, Sergiusz Wójcik, Jakub Maćkowiak,
            Gabriela Kupś, Karolina Kupś, Dominika Jaworowicz, Martyna Sobisiak,
            Kinga Fliegier, Tobiasz Plewa, Weronika Macioszek oraz Karol
            Gładziszewski.
          </p>
          <p>
            LKS „Budowlani” zrzesza w swoich szeregach największą w Polsce
            liczbę zawodników podnoszenia ciężarów. Do klubu należą też młodsi
            sportowcy, wśród dzieci poniżej 13 roku życia, trenujących w LKS
            „Budowlani-Całus” i startujących w zawodach wielobojów atletycznych,
            są: dziewczynki – Weronika Bimkiewicz, Kinga Bimkiewicz, Nicola
            Cebernik, Julita Jandernal, Nadia Janowska, Lena Jórek, Otylia
            Kaczmarek, Lena Kazimierczak, Milena Kędzierska, Zuzanna Molenda,
            Lena Nowak, Oliwia Nowak, Weronika Ratajczak, Maja Szmyt, Maria
            Śledź, Maria Zakrzewska i Amelia Zioła; a także chłopcy – Julian
            Błaszczyk, Szymon Brodala, Dawid Chojnacki, Krystian Dziamski,
            Hubert Głaczyński, Mikołaj Juras, Maciej Kędzierski, Adrian Krupa,
            Jakub Kwaśny, Krzysztof Kwaśny, Dawid Lisiak, Kacper Łuczak, Szymon
            Mackiewicz, Bartosz Mrozik, Adrian Piosik, Patryk Ryciak, Franciszek
            Skręty oraz Nikodem Torchała.
          </p>
          <p>
            W trakcie trzech dekad działalności klubu doceniano jego trenerów. W
            strukturach WZPC i PZPC działali: Paweł Najdek, Jerzy Nowak,
            Wojciech Piechowiak, Janusz Wróblewski, Marcin Lampe i Robert
            Salwowski. Selekcjonerem kadr narodowych był Paweł Najdek, a obecnie
            jest nim Marcin Lampe.
          </p>
          <p>
            LKS „Budowlani”, poza szkoleniem w Nowym Tomyślu (w MCPC), gdzie
            trenerami są Jerzy Nowak i Marcin Lampe, prowadzi aktualnie
            podsekcje podnoszenia ciężarów w następujących ościennych
            miejscowościach: Zbąszyń (w Zespole Szkół) – szkoleniowiec Juliusz
            Zbierski (sekcja od 2011 roku), Boruja Kościelna (Szkoła Podstawowa)
            – Romuald Jezierski (od 2011), Michorzewo (SP) – Krzysztof Horodecki
            (OSP), Lwówek (OSiR) – Marcin Lampe i Krzysztof Horodecki, Bukowiec
            (Szkoła Podstawowa), Sątopy (Sala Wiejska) i Wytomyśl (Sala Wiejska)
            – Patrycja Piechowiak (od 2018), Bolewice (Stadion) – początkowo
            Jerzy Nowak i Marcin Lampe (od 2017) obecnie Mateusz Miczyński,
            Pniewy (OSiR) – Mateusz Miczyński (od 2018), Poznań (Go With the
            FLOW) – Dobrosława Kucharzak (od 2017), Poznań (SP 74) – Piotr
            Słabuszewski (od 2017) oraz Wolsztyn – Jerzy Nowak (od 2019).
            Istniały również sekcje w Kuślinie i Wąsowie – Krzysztof Horodecki
            oraz Jastrzębsku Starym i Starym Tomyślu – Jerzy Nowak.
          </p>
          <p>
            Opieką żywieniową zawodników zajmuje się mgr Grzegorz Kępa, opieką
            fizjoterapeutyczną i osteopatyczną mgr Rafał Sara, a trenerem
            przygotowania motorycznego jest mgr Bartosz Malak.
          </p>
          <p>
            Kadrę trenerską „Budowlanych” tworzyli również Adrian Pawlicki oraz
            Wojciech Piechowiak, uprawnienia trenerskie posiadają też niektórzy
            członkowie klubu.
          </p>
          <h4>Autor: Tomasz Siwiński</h4>
        </div>
      </section>
    </>
  );
};
export default History;
