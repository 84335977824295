import React from "react";
import { HashLink as Link } from "react-router-hash-link";

import "./_buttons.scss";

const ButtonBorder = (props) => {
  const scrollWithOffset = (el) => {
    let yOffset = -100;
    if (window.innerWidth < 767) {
      yOffset = 0;
    } else {
      yOffset = -100;
    }

    const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;

    window.scrollTo({ top: yCoordinate + yOffset, behavior: "smooth" });
  };

  return (
    <Link
      ref={props.ref}
      smooth
      to={props.to || "/"}
      scroll={(el) => scrollWithOffset(el)}
    >
      <div className={`btn-border   ${props.cName}`}>{props.title}</div>
    </Link>
  );
};

export default ButtonBorder;
