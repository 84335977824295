import React from "react";
import "./_pagination.scss";

const Pagination = ({
  postsPerPage,
  totalPosts,
  prevPage,
  paginate,
  nextPage,
  currentPage,
}) => {
  const pageNumbers = [];

  for (let i = 1; i <= Math.ceil(totalPosts / postsPerPage); i++) {
    pageNumbers.push(i);
  }

  return (
    <nav>
      <ul className="pagination">
        <li>
          <button
            onClick={() => prevPage()}
            className="pagination-prev"
          ></button>
        </li>
        <ul className="page-numbers">
          {pageNumbers.map((number) => (
            <li
              key={number}
              className={
                currentPage === number
                  ? "page-item page-item-active"
                  : "page-item"
              }
            >
              <button
                onClick={() => paginate(number)}
                className="page-link"
                to="#"
                //   to={{
                //     pathname: "",
                //     hash: "#news",
                //   }}
              >
                {number}
              </button>
            </li>
          ))}
        </ul>
        <li>
          <button
            onClick={() => nextPage(pageNumbers.length)}
            className="pagination-next"
          ></button>
        </li>
      </ul>
    </nav>
  );
};

export default Pagination;
