import React, { useEffect } from "react";

import { Switch, Route, useLocation } from "react-router-dom";
import ParralaxCache from "./components/Parallax/ParallaxCache";
import SimpleReactLightbox from "simple-react-lightbox";
// import { TransitionGroup, CSSTransition } from "react-transition-group";
import "./App.scss";
import Navbar from "./components/Navbar/Navbar.jsx";
import Home from "./pages/Home.jsx";
import Footer from "./components/Footer/Footer.jsx";
import History from "./pages/History";
// import Deserved from "./pages/Deserved";
// import DeservedSingle from "./pages/DeservedSingle";
// import Statistic from "./pages/Statistic";
import News from "./pages/News";
import NewsSingle from "./pages/NewsSingle";
import Players from "./pages/Players";
import PlayerSingel from "./pages/PlayerSingel";
import Coach from "./pages/Coach";
import Sections from "./pages/Sections";
import Management from "./pages/Management";
import Gallery from "./pages/Gallery";
import Contact from "./pages/Contact";
// import JoinUs from "./pages/JoinUs";
import SiteInWork from "./pages/SiteInWork";
import Volunteers from "./pages/Volunteers";
import SupportUs from "./pages/SupportUs";

const App = () => {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <>
      <Navbar navbarLocation={location.pathname} /> <ParralaxCache />
      <Switch location={location}>
        <Route exact path="/">
          <Home location={location} />
        </Route>
        <Route exact path="/historia">
          <History />
        </Route>
        <Route exact path="/zasluzeni">
          <SiteInWork />
        </Route>
        <Route path="/zasluzeni/:slug">
          {/* <DeservedSingle /> */}
          <SiteInWork />
        </Route>
        <Route exact path="/statystyki">
          {/* <Statistic /> */}
          <SiteInWork />
        </Route>
        <Route exact path="/aktualnosci">
          <News />
        </Route>
        <Route path="/aktualnosci/:slug">
          <SimpleReactLightbox>
            <NewsSingle />
          </SimpleReactLightbox>
        </Route>
        <Route exact path="/zarzad">
          <Management />
        </Route>
        <Route exact path="/zawodnicy">
          <Players />
        </Route>
        <Route exact path="/zawodnicy/:slug">
          <SimpleReactLightbox>
            <PlayerSingel />
          </SimpleReactLightbox>
        </Route>
        <Route exact path="/trenerzy">
          <Coach />
        </Route>
        <Route exact path="/sekcje">
          <Sections />
        </Route>
        <Route exact path="/wolontariusze">
          <Volunteers />
        </Route>
        <Route exact path="/galeria">
          <SimpleReactLightbox>
            <Gallery />
          </SimpleReactLightbox>
        </Route>

        <Route exact path="/formularz">
          <Contact />
        </Route>
        <Route exact path="/nabor-do-sekcji">
          {/* <JoinUs />
           */}
          <SiteInWork />
        </Route>
        <Route exact path="/wesprzyj-nas">
          {/* <JoinUs />
           */}
          <SupportUs />
        </Route>
        <Route exact path="/silownia">
          {/* <JoinUs />
           */}
          <SiteInWork />
        </Route>
      </Switch>
      <Footer />
    </>
  );
};

export default App;
