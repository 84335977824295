import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import App from "./App.jsx";
import { ParallaxProvider } from "react-scroll-parallax";
import CookieConsent from "react-cookie-consent";
// import CookieConsent, { Cookies } from "react-cookie-consent";
import { FaFileAlt } from "react-icons/fa";

ReactDOM.render(
  <React.Fragment>
    <ParallaxProvider>
      <BrowserRouter>
        <App />
        <CookieConsent
          className="coockie"
          location="bottom"
          buttonText="Zgoda"
          expires={365}
          cookieName="budowlani-kucera-Cookie"
          disableStyles={true}
          disableButtonStyles={true}
          containerClasses="coockie-container"
          contentClasses="coockie-content"
          buttonClasses="btn-coockie"
          enableDeclineButton
          declineButtonText="Odrzuć"
          declineButtonClasses="btn-coockie btn-decline"
        >
          <p>
            Ta strona korzysta z ciasteczek aby świadczyć usługi na najwyższym
            poziomie. Dalsze korzystanie ze strony oznacza, że zgadzasz się na
            ich użycie.{" "}
          </p>{" "}
          <a
            className="coockie-politic"
            target="_blank"
            href="/assets/polityka.pdf"
          >
            <FaFileAlt
              className="politics-icon
            "
            />
            Polityka Prywatności{" "}
          </a>
        </CookieConsent>
      </BrowserRouter>
    </ParallaxProvider>
  </React.Fragment>,
  document.getElementById("root")
);
