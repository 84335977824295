import React from "react";
import "./_playerPageCard.scss";

import ReactMarkdown from "react-markdown";
// import { SRLWrapper } from "simple-react-lightbox";

const PlayerPageCard = ({
  name,
  imgPlayer,
  alt,
  year,
  category,
  record,
  imgSponsor,
  text,

  score,
  medal,
}) => {
  return (
    <>
      <h2>{name}</h2>
      <div className="player-p-container">
        <div className="player-item">
          <div>
            <img
              src={imgPlayer}
              alt={alt}
              className="player-info-data-coverImg"
            />
          </div>
          <div className="player-item-info">
            {year ? (
              <div className="player-info-data">
                <img
                  src="/assets/icons/player_card/rocznik.svg"
                  alt="rocznik zawodnika"
                />
                <h3>
                  Rocznik: <span>{year}</span>
                </h3>
              </div>
            ) : (
              ""
            )}
            {category ? (
              <div className="player-info-data">
                <img
                  src="/assets/icons/player_card/kategoria.svg"
                  alt="rocznik zawodnika"
                />
                <h3>
                  Kategoria: <span>{category}</span>
                </h3>
              </div>
            ) : (
              ""
            )}
            {record ? (
              <div className="player-info-data">
                <img src="/assets/icons/player_card/medal.svg" alt="rekord" />
                <h3 className="red">
                  Rekord: <span>{record}</span>
                </h3>
              </div>
            ) : (
              ""
            )}
            {score ? (
              <div className="player-info-data">
                <img src="/assets/icons/player_card/wynik.svg" alt="wynik" />
                <h3>
                  Wynik: <span>{score}</span>
                </h3>
              </div>
            ) : (
              ""
            )}
            {medal ? (
              <div className="player-info-data">
                <img src="/assets/icons/player_card/medal.svg" alt="medale" />
                <h3 className="red">
                  medal: <span>{medal}</span>
                </h3>
              </div>
            ) : (
              ""
            )}
          </div>
          {imgSponsor ? (
            <>
              <div className="player-item-line"></div>
              <div className="player-item-sponsor">
                <p>Sponsor:</p>
                <img src={imgSponsor} alt="logo sponsora" />
              </div>
            </>
          ) : (
            ""
          )}
        </div>
        <ReactMarkdown
          components={{
            // Map `h1` (`# heading`) to use `h2`s.
            h1: "h2",
          }}
        >
          {text}
        </ReactMarkdown>
      </div>
    </>
  );
};
export default PlayerPageCard;
