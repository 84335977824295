const SearchLogic = (news, searchQuery) => {
  const searchNews = () => {
    if (!searchQuery && searchQuery.trim() === false) {
      return news;
    }

    return news.filter((news) => {
      let newsTitle =
        news.tytul !== null ? news.tytul.toLowerCase() : "brak tytułu";

      let queryWords = [...searchQuery.trim().toLowerCase().split(" ")];
      queryWords.filter((word) => /\S/.test(word) || word !== " ");

      let isTrue;
      for (let i = 0; i < queryWords.length; i++) {
        if (newsTitle.includes(queryWords[i])) {
          isTrue = true;
        } else isTrue = false;
      }
      if (isTrue) {
        return news;
      } else return false;
    });
  };

  return { searchNews };
};

export default SearchLogic;
