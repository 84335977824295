import React from "react";
import "./_sectionCard.scss";
import { HashLink } from "react-router-hash-link";
const SectionCard = ({
  name,
  imgSection,
  alt,
  phone,
  mail,
  adress,
  coaches,
}) => {
  const cmsUrl = process.env.REACT_APP_BACKEND_URL;

  const scrollWithOffset = (el) => {
    let yOffset;
    if (window.innerWidth < 767) {
      yOffset = 0;
    } else {
      yOffset = -100;
    }

    let yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
    window.scrollTo({
      top: yCoordinate + yOffset,
      behavior: "smooth",
    });
  };

  const CoachesToRender = coaches.map((coach, i) => {
    let imgCoach = cmsUrl + coach.zdjecie_profilowe.formats.small.url;
    return (
      <HashLink
        key={i}
        smooth
        to={`/trenerzy#${coach.id}`}
        scroll={(el) => scrollWithOffset(el)}
      >
        <div className="section-info-coaches-data">
          <div className="section-info-coaches-data-img">
            <img src={imgCoach} alt="trener" />
          </div>
          <span>{coach.imie_nazwisko}</span>
        </div>
      </HashLink>
    );
  });

  return (
    <>
      <div className="section-p-container">
        <div className="section-item">
          <div className="section-info-data-coverImg">
            <img src={imgSection} alt={alt} />
          </div>
          <div className="section-item-info">
            {name ? (
              <div className="section-info-data">
                <h3>{name}</h3>
              </div>
            ) : (
              ""
            )}
            {phone ? (
              <a itemProp="phone" href={`tel:+48${phone.split(" ").join("")}`}>
                <div className="section-info-data">
                  <img
                    src="/assets/icons/telefon.svg"
                    alt="rocznik zawodnika"
                  />

                  <span>{phone}</span>
                </div>
              </a>
            ) : (
              ""
            )}
            {mail ? (
              <a itemProp="email" href={`mailto:${mail}`}>
                <div className="section-info-data">
                  <img src="/assets/icons/mail.svg" alt="rocznik zawodnika" />

                  <span>{mail}</span>
                </div>
              </a>
            ) : (
              ""
            )}
            {adress ? (
              <div className="section-info-data">
                <span className="section-info-adress">{adress}</span>
              </div>
            ) : (
              ""
            )}
          </div>
          <div className="section-item-line"></div>

          <div className="section-info-coaches">
            {coaches ? <p>Trenerzy:</p> : ""}
            {CoachesToRender}
          </div>
        </div>
      </div>
    </>
  );
};
export default SectionCard;
