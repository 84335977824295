import React, { useLayoutEffect, useEffect, useState } from "react";
import { Parallax, Background } from "react-parallax";
import { Parallax as Para } from "react-scroll-parallax";
import "./_header.scss";
import GoDown from "../Buttons/GoDown.jsx";

const Header = (props) => {
  const [isMobile, setIsMobile] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  const handelWindowResize = () => {
    if (window.innerWidth > 960) {
      return setIsMobile(false);
    } else return setIsMobile(true);
  };

  useLayoutEffect(() => {
    window.addEventListener("DOMContentLoaded", handelWindowResize);
    return () => {
      window.removeEventListener("DOMContentLoaded", handelWindowResize);
    };
  }, []);

  useEffect(() => {
    window.addEventListener("resize", handelWindowResize);
    return () => {
      window.removeEventListener("resize", handelWindowResize);
    };
  }, []);

  const handelScrollTo = () => {
    let scrollTo = props.refScrollTo.current.offsetTop;

    // window.scrollTo({
    //   top: scrollTo - 100,
    //   behavior: "smooth",
    // });

    if (window.innerWidth < 767) {
      window.scrollTo({
        top: scrollTo,
        behavior: "smooth",
      });
    } else {
      window.scrollTo({
        top: scrollTo - 100,
        behavior: "smooth",
      });
    }
  };

  return (
    <>
      <header>
        <Parallax className="header-background" strength={300}>
          <Background>
            <img
              className={isLoading ? "display-none" : ""}
              src={isMobile ? props.srcMobile : props.srcDesktop}
              alt={props.alt}
              onLoad={() => {
                setIsLoading(false);
              }}
            />
            <div className="header-bg-layer"></div>
          </Background>
        </Parallax>
        <h1>{props.heading}</h1>
        <div className="row-width">
          <div className="slogan-container">
            <Para translateX={[85, -120]}>
              <div className="slogan slogan-left">#dawajmocno</div>
            </Para>
            <Para translateX={[-36, 80]}>
              <div className="slogan slogan-right">#najsilniejsi</div>
            </Para>
          </div>
        </div>
        {isLoading ? <div className="loader"></div> : ""}

        <GoDown handleScrollTo={handelScrollTo} />
      </header>
    </>
  );
};
export default Header;
