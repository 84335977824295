import React from "react";
import "./_newsCard.scss";
import { Link } from "react-router-dom";

// import BtnArrow from "../Buttons/BtnArrow";

const NewsCard = (props) => {
  let data = props.data;

  return (
    <>
      <Link to={props.to || "/"}>
        <div className="news-card">
          <div className="img-box">
            <img src={props?.src} alt="news" />
          </div>
          <div className="news-card-text">
            <h3>{props?.title}</h3>
            <p>{props?.description}</p>
            <h4>{props?.autor}</h4>
          </div>
          <h4 className="news-card-data">{data}</h4>

          {/* <div className="news-card-onhover">
          <BtnArrow cName="news-btn" title="Więcej" to={props.to} />
        </div> */}
        </div>
      </Link>
    </>
  );
};
export default NewsCard;
