import React from "react";
import "./_footer.scss";

const Footer = () => {
  return (
    <>
      <div className="footer-bottom">
        <footer>
          <div className="row-width">
            <div className="footer-container">
              <div className="footer-item">
                <h4>LKS “Budowlani-Kucera”</h4>
                <p>
                  os. Północ 37<br></br> 64-300 Nowy Tomyśl
                </p>
              </div>
            </div>
          </div>
        </footer>

        <div className="copy-right">
          <div className="copy-right-line"></div>
          <div className="row-width">
            <div className="copy-right-container">
              <div className="copy-right-item">
                {/* <a
                  href="https://pigwastudio.pl"
                  target="_blank"
                  rel="noopener noreferrer"
                > */}
                <div className="copy-right-text">
                  © Copyright 2020 Budowlani-Kucera LKS
                </div>
                {/* <nobr> | Design by Pigwa Studio</nobr> */}
                {/* </a> */}
              </div>

              <div className="copy-right-item">
                <a target="_blank" href="/assets/polityka.pdf">
                  Polityka Prywatoności
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Footer;
