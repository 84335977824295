import React, { useState } from "react";
import { Parallax, Background } from "react-parallax";

import "./_headerPages.scss";

const HeaderPages = (props) => {
  const [isLoading, setIsLoading] = useState(true);

  return (
    <>
      <Parallax className="header-pages" strength={500}>
        <Background>
          <img
            className={isLoading ? "display-none" : "header-pages-img"}
            src={props.src}
            alt={props.alt}
            onLoad={() => {
              setIsLoading(false);
            }}
          />
        </Background>
      </Parallax>
    </>
  );
};
export default HeaderPages;
