import React from "react";
import "./_buttons.scss";

const ButtonBorderNoLink = (props) => {
  return (
    <div
      onClick={props.onClick}
      name={props.name}
      className={`btn-border   ${props.cName}`}
    >
      {props.title}
    </div>
  );
};
export default ButtonBorderNoLink;
