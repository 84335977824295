import React from "react";
import "./_coach.scss";

import HeaderPages from "../components/Header/HeaderPages";
import CoachCard from "../components/Cards/CoachCard";
import useAxiosGet from "../components/Axios/useAxiosGet";
const Coach = () => {
  const urlCoach = "/trenerzy";

  const { response: coach, isLoading, error, cmsUrl } = useAxiosGet(urlCoach);

  console.log(error);

  const CoachToRender = isLoading ? (
    <div className="loader"></div>
  ) : (
    coach
      .sort((a, b) =>
        a.imie_nazwisko
          .match(/\S+/gi)[1]
          .localeCompare(b.imie_nazwisko.match(/\S+/gi)[1])
      )
      .sort((a, b) => {
        if (a.priorytet === b.priorytet) {
          return 0;
        } else if (a.priorytet === null) {
          return -1;
        } else if (b.priorytet === null) {
          return -1;
        } else return a.priorytet < b.priorytet ? -1 : 1;
      })
      .map((item, i) => {
        let imgManager = cmsUrl + item.zdjecie_profilowe.formats.medium.url;
        return (
          <CoachCard
            id={item.id}
            key={i}
            name={item.imie_nazwisko}
            imgManager={imgManager}
            position={item.stanowisko}
            contact={item.kontakt}
            mail={item.e_mail}
            text={item.opis}
          />
        );
      })
  );

  return (
    <>
      <HeaderPages src={"/assets/img/header-pages/news.jpg"} />
      <section className="coach-section-p">
        <div className="row-width">
          <h2>Trenerzy</h2>
          <div className="coach-container">
            {/* <img
              className="coach-img"
              src="/assets/temporal/management-team.jpg"
              alt="zarząd"
            /> */}
            {CoachToRender}
          </div>
        </div>
      </section>
    </>
  );
};
export default Coach;
