import React from "react";
import "./_godown.scss";
const GoDown = (props) => {
  return (
    <>
      <div onClick={() => props.handleScrollTo()} className="godown">
        <span>{props.title}</span>
        <img src="/assets/icons/godown.svg" alt="scrolluj" />
      </div>
    </>
  );
};
export default GoDown;
