import React from "react";
import "./_playerCard.scss";
import { useRouteMatch } from "react-router-dom";
import { Link } from "react-router-dom";

const PlayerCard = ({ id, imgPlayer, alt, name }) => {
  const { url } = useRouteMatch();
  return (
    <>
      <Link to={`${url}/${id}`}>
        <div className="players-item">
          <img src={imgPlayer} alt={alt} />
          <h3>{name}</h3>
        </div>
      </Link>
    </>
  );
};
export default PlayerCard;
