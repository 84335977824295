import React from "react";
import "./_sponsorCard.scss";

const SponsorCard = (props) => {
  return (
    <>
      <div className="sponsor-item">
        <div className={props.cName}>
          <img src={props.src} alt={props.alt} />
        </div>
        <h4>{props.title}</h4>
      </div>
    </>
  );
};
export default SponsorCard;
