import React, { useState, useEffect } from "react";
import "./_section.scss";

import axios from "axios";

import HeaderPages from "../components/Header/HeaderPages";
import SectionCard from "../components/Cards/SectionCard";
const Sections = () => {
  // const { url } = useRouteMatch();
  const cmsUrl = process.env.REACT_APP_BACKEND_URL;
  const [section, setSection] = useState([]);

  useEffect(() => {
    const getSection = async () => {
      try {
        await axios.get(`${cmsUrl}/sekcje`).then((response) => {
          const resp = response.data;
          return setSection(resp);
        });
      } catch (error) {
        console.error(error);
      }
    };
    getSection();
  }, [cmsUrl]);

  const SectionToRender = section.map((item, i) => {
    let imgSection = cmsUrl + item.zdjecie[0].url;

    return (
      <SectionCard
        key={i}
        name={item.nazwa}
        imgSection={imgSection}
        alt={item.alt_tag_zdjecia}
        phone={item.kontakt}
        mail={item.e_mail}
        adress={item.adres}
        coaches={item.trenerzy}
      />
    );
  });

  return (
    <>
      <HeaderPages src={"/assets/img/header-pages/news.jpg"} />
      <section className="section-section-p">
        <div className="row-width">
          <h2>Sekcje</h2>
          <div className="section-container">
            {/* <img
              className="section-img"
              src="/assets/temporal/management-team.jpg"
              alt="zarząd"
            /> */}
            {SectionToRender}
          </div>
        </div>
      </section>
    </>
  );
};
export default Sections;
