import React from "react";
import "./_supportUs.scss";
import HeaderPages from "../components/Header/HeaderPages";

const SupportUs = () => {
  return (
    <>
      <HeaderPages src={"/assets/img/header-pages/news.jpg"} />
      <section className="supportus-section-p">
        <div className="row-width">
          <h2>Wspieraj młodych mistrzów</h2>

          <div className="supportus-box">
            <img
              className="supportus-box-icon"
              src="/assets/icons/wspieraj-w.svg"
              alt="wspieraj"
            />
            <div className="supportus-box-text">
              <p>Przekaż 1% podatku</p>
              <p>KRS 0000137358</p>
              <p>z dopiskiem Budowlani-Kucera</p>
            </div>
            <img
              className="supportus-bar-img"
              src="/assets/img/PIOTR_KUDLASZYK_shadow.png"
              alt="Piotr Kudlaszyk"
            />
          </div>
        </div>
      </section>
      ;
    </>
  );
};

export default SupportUs;
