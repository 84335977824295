import React, { useState, useEffect } from "react";
import "./_news.scss";

import useAxiosGet from "../components/Axios/useAxiosGet";
import { useRouteMatch } from "react-router-dom";

import HeaderPages from "../components/Header/HeaderPages";
import NewsCard from "../components/News/NewsCard";
import Search from "../components/Search/Search";
import SearchLogic from "../components/Search/SearchLogic";

import Pagination2 from "../components/Pagination/Pagination2";

// const NewsToRender = !currentPosts ? (
//   <div className="loader"></div>
// ) : currentPosts.length !== 0 ? (
//   currentPosts.map((news, i) => {
//     const imgUrl = news.okladka.formats.small
//       ? news.okladka.formats.small.url
//       : news.okladka.url;
//     return (
//       <NewsCard
//         key={i}
//         src={cmsUrl + imgUrl}
//         title={news.tytul}
//         description={news.opis.slice(0, 120) + "..."}
//         to={`${url}/${news.id}`}
//         data={news.data}
//       />
//     );
//   })
// ) : (
//   <p className="news-search-noresult">
//     artykuł, którego szukasz nie został znaleziony...
//   </p>
// );

const News = () => {
  const { url } = useRouteMatch();

  const [searchQuery, setSearchQuery] = useState("");

  // const urlRequest = "/artykuly?_limit=-1";

  const urlRequest = "/artykuly?_limit=-1";

  const { response, isLoading, error, cmsUrl } = useAxiosGet(urlRequest);
  if (error) {
    console.log(error);
  }

  let data = response;

  const { searchNews } = SearchLogic(response, searchQuery);

  const filteredNews = searchNews(response, searchQuery).sort(
    (a, b) => new Date(b.data) - new Date(a.data)
  );

  data = filteredNews;

  //Pagination 2

  const [postsPerPage] = useState(6);
  const [numberOfPages] = useState(3);

  data = filteredNews;

  const { pageNumbersToRender, currentPosts, setActivePage } = Pagination2({
    searchQuery,
    isLoading,
    data,
    postsPerPage,
    numberOfPages,
  });
  useEffect(() => {
    setActivePage(1);
  }, [searchQuery, setActivePage]);

  // const NewsToRender = !currentPosts ? (
  //   <div className="loader"></div>
  // ) : currentPosts.length !== 0 ? (
  //   currentPosts.map((news, i) => {
  //     const imgUrl = news.okladka.formats.small
  //       ? news.okladka.formats.small.url
  //       : news.okladka.url;
  //     return (
  //       <NewsCard
  //         key={i}
  //         src={cmsUrl + imgUrl}
  //         title={news.tytul}
  //         description={news.opis.slice(0, 120) + "..."}
  //         to={`${url}/${news.id}`}
  //         data={news.data}
  //       />
  //     );
  //   })
  // ) : (
  //   <p className="news-search-noresult">
  //     artykuł, którego szukasz nie został znaleziony...
  //   </p>
  // );
  let NewsToRender;

  if (!isLoading && currentPosts.length) {
    NewsToRender = currentPosts.map((news, i) => {
      const imgUrl = news.okladka.formats.small
        ? news.okladka.formats.small.url
        : news.okladka.url;
      return (
        <NewsCard
          key={i}
          src={cmsUrl + imgUrl}
          title={news.tytul}
          description={news.opis ? `${news.opis.slice(0, 120)}...` : ""}
          to={`${url}/${news.id}`}
          data={news.data}
        />
      );
    });
  }

  if (isLoading) NewsToRender = <div className="loader"></div>;

  if (searchQuery && !currentPosts.length)
    NewsToRender = (
      <p className="news-search-noresult">
        artykuł, którego szukasz nie został znaleziony...
      </p>
    );

  return (
    <>
      <HeaderPages src={"/assets/img/header-pages/news.jpg"} />
      <section id="start" className="news-section-p">
        <div className="row-width">
          <h2>Aktualności</h2>
          <Search
            className="news-section-search"
            searchQuery={searchQuery}
            setSearchQuery={setSearchQuery}
          />
          <div id="news" className="news-container">
            {NewsToRender}
          </div>
          {isLoading ? "" : pageNumbersToRender}
        </div>
      </section>
    </>
  );
};
export default News;
