import { useState } from "react";

const PaginationLogic = (galleryPerPage) => {
  const numberPerPage = galleryPerPage ? galleryPerPage : 6;

  const [postsPerPage] = useState(numberPerPage);
  const [currentPage, setCurrentPage] = useState(1);

  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;

  return {
    postsPerPage,
    currentPage,
    indexOfLastPost,
    indexOfFirstPost,
    setCurrentPage,
  };
};

export default PaginationLogic;
