import React, { useState, useEffect } from "react";
import "./_volunteers.scss";

import axios from "axios";

import HeaderPages from "../components/Header/HeaderPages";
import VolunteersCard from "../components/Cards/VolunteersCard";
const Volunteers = () => {
  // const { url } = useRouteMatch();
  const cmsUrl = process.env.REACT_APP_BACKEND_URL;

  const [volunteers, setvolunteers] = useState([]);

  useEffect(() => {
    const getvolunteers = async () => {
      try {
        await axios.get(`${cmsUrl}/wolontariusze`).then((response) => {
          const resp = response.data;
          return setvolunteers(resp);
        });
      } catch (error) {
        console.error(error);
      }
    };
    getvolunteers();
  }, [cmsUrl]);

  const volunteersToRender = volunteers.map((item, i) => {
    let imgManager;

    if (item.zdjecie_profilowe) {
      imgManager = cmsUrl + item.zdjecie_profilowe.url;
    } else imgManager = "/assets/img/budowlani-mis.png";
    return (
      <VolunteersCard
        key={i}
        name={item.imie_nazwisko}
        imgManager={imgManager}
        position={item.stanowisko}
        contact={item.nr_kontaktowy}
        mail={item.e_mail}
        text={item.opis}
      />
    );
  });

  return (
    <>
      <HeaderPages src={"/assets/img/header-pages/news.jpg"} />
      <section className="volunteers-section-p">
        <div className="row-width">
          <h2>Wolontariusze</h2>
          <div className="volunteers-container">{volunteersToRender}</div>
        </div>
      </section>
    </>
  );
};
export default Volunteers;
