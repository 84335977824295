import React from "react";
import "./_newsSingle.scss";
import useAxiosGet from "../components/Axios/useAxiosGet";
import { useParams } from "react-router-dom";
import { SRLWrapper } from "simple-react-lightbox";
import ButtonBorder from "../components/Buttons/ButtonBorder";

import ReactMarkdown from "react-markdown";
import Moment from "react-moment";
import "moment/locale/pl";
import HeaderPages from "../components/Header/HeaderPages";

const options = {
  settings: {
    // overlayColor: "rgb(25, 136, 124)",
    autoplaySpeed: 4000,
    transitionSpeed: 900,
  },
  buttons: {
    backgroundColor: "rgba(30,30,36,0.8)",
    iconColor: "rgba(255, 255, 255, 0.8)",
    iconPadding: "10px",
    showAutoplayButton: true,
    showCloseButton: true,
    showDownloadButton: false,
    showFullscreenButton: true,
    showNextButton: true,
    showPrevButton: true,
    showThumbnailsButton: true,
    size: "40px",
  },
  // caption: {
  //   captionColor: "#a6cfa5",
  //   captionFontFamily: "Raleway, sans-serif",
  //   captionFontWeight: "300",
  //   captionTextTransform: "uppercase",
  // },
  // progressBar: {
  //   height: "20px",
  //   fillColor: "blue",
  //   backgroundColor: "white",
  // },
};

const NewsSignle = () => {
  const { slug } = useParams();
  const cmsUrl = process.env.REACT_APP_BACKEND_URL;

  const urlRequest = `/artykuly/${slug}/`;

  const { response: singleNews, isLoading, error } = useAxiosGet(urlRequest);
  if (error) {
    console.log(error);
  }

  const CoverImgToRender = isLoading ? (
    <div className="loader"></div>
  ) : (
    <img
      className="news-single-imgcover"
      src={cmsUrl + singleNews.okladka.url}
      alt="budowlani"
    />
  );

  const TextToRender = singleNews.opis;
  const ImgsToRender = singleNews.galeria ? (
    isLoading ? (
      <div className="loader"></div>
    ) : (
      singleNews.galeria.map((imgs, i) => {
        return <img key={i} src={cmsUrl + imgs.url} alt={imgs.hash} />;
      })
    )
  ) : (
    ""
  );

  function LinkRenderer(props) {
    return (
      <a href={props.href} target="_blank" rel="noreferrer">
        {props.children}
      </a>
    );
  }
  return (
    <>
      <HeaderPages
        src={"/assets/img/header-pages/news.jpg"}
        alt="LKS Budowlani"
      />

      <section className="news-single-section-p">
        <div className="row-width">
          <h2>{singleNews.tytul}</h2>
          {CoverImgToRender}
          <ReactMarkdown
            // renderers={{ link: LinkRenderer }}
            linkTarget="_blank"
            components={{
              h1: "h2",
              link: () => <link {...LinkRenderer} />,
            }}
            children={TextToRender}
          />
          <SRLWrapper className="srlwrapper" options={options}>
            <div className="news-single-gallery">{ImgsToRender}</div>
          </SRLWrapper>
          <div className="news-single-autor-data">
            <span>{singleNews.autor}</span>
            <span>
              <Moment locale="pl- PL" format="Do MMM YYYY">
                {singleNews.data}
              </Moment>
            </span>
          </div>
          <ButtonBorder
            title={"aktualności"}
            to={"/aktualnosci#start"}
            cName="btn-blue btn-news-singel"
          />
        </div>
      </section>
    </>
  );
};
export default NewsSignle;
