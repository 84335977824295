import React, { useEffect, useState } from "react";
import "./_gallery.scss";
import HeaderPages from "../components/Header/HeaderPages.jsx";
import { SRLWrapper } from "simple-react-lightbox";
// import TagButton from "../components/Buttons/TagButton.jsx";
import axios from "axios";

import Pagination from "../components/Pagination/Pagination";
import PaginationLogic from "../components/Pagination/PaginationLogic";

const options = {
  settings: {
    // overlayColor: "rgb(25, 136, 124)",
    autoplaySpeed: 4000,
    transitionSpeed: 900,
  },
  buttons: {
    backgroundColor: "rgba(30,30,36,0.8)",
    iconColor: "rgba(255, 255, 255, 0.8)",
    iconPadding: "10px",
    showAutoplayButton: true,
    showCloseButton: true,
    showDownloadButton: true,
    showFullscreenButton: true,
    showNextButton: true,
    showPrevButton: true,
    showThumbnailsButton: true,
    size: "40px",
  },
  // caption: {
  //   captionColor: "#a6cfa5",
  //   captionFontFamily: "Raleway, sans-serif",
  //   captionFontWeight: "300",
  //   captionTextTransform: "uppercase",
  // },
  // progressBar: {
  //   height: "20px",
  //   fillColor: "blue",
  //   backgroundColor: "white",
  // },
};

const Gallery = () => {
  const cmsUrl = process.env.REACT_APP_BACKEND_URL;

  const [gallery, setGallery] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // Get Gallery

    const getGallery = async () => {
      try {
        await axios.get(`${cmsUrl}/galeria`).then((response) => {
          const resp = response.data;
          setGallery(resp);
          return setIsLoading(false);
        });
      } catch (error) {
        console.error(error);
      }
    };
    getGallery();
  }, [cmsUrl]);

  const galleryPerPage = 3;

  const {
    postsPerPage,
    currentPage,
    indexOfLastPost,
    indexOfFirstPost,
    setCurrentPage,
  } = PaginationLogic(galleryPerPage);

  const currentGallery = gallery
    .sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
    .slice(indexOfFirstPost, indexOfLastPost);

  // Change page
  const prevPage = () =>
    currentPage === 1 ? "" : setCurrentPage(currentPage - 1);
  const paginate = (pageNumber) => setCurrentPage(pageNumber);
  const nextPage = (pageNumber) =>
    currentPage === pageNumber ? "" : setCurrentPage(currentPage + 1);

  const GalleryToRender = currentGallery
    .sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
    .map((gallery, i) => {
      return (
        <div key={i} className="gallery-item">
          <h3>{gallery.nazwa}</h3>

          {gallery.zdjecia.map((item, i) => {
            return (
              <div key={i} className="image-card">
                <a href={cmsUrl + item.url}>
                  <img
                    src={cmsUrl + item.formats.small.url}
                    alt={item.caption ? item.caption : gallery.nazwa}
                  />
                </a>
              </div>
            );
          })}
        </div>
      );
    });

  return (
    <>
      <HeaderPages
        src={"/assets/img/header-pages/news.jpg"}
        alt={"Budowlani Galeria"}
      />
      <section className="gallery-p-section">
        <div className="row-width">
          <h2>Galeria</h2>

          {isLoading ? <div className="loader"></div> : ""}
          <SRLWrapper className="srlwrapper" options={options}>
            <div className="gallery-container">{GalleryToRender}</div>
          </SRLWrapper>

          <Pagination
            postsPerPage={postsPerPage}
            totalPosts={gallery.length}
            prevPage={prevPage}
            paginate={paginate}
            nextPage={nextPage}
            currentPage={currentPage}
          />
        </div>
      </section>
    </>
  );
};

export default Gallery;
