import React, { useEffect, useRef, useState } from "react";
import "./_home.scss";
import Header from "../components/Header/Header.jsx";
import NewsCard from "../components/News/NewsCard.jsx";
import SponsorCard from "../components/Sponsors/SponsorCard.jsx";
import ButtonBorder from "../components/Buttons/ButtonBorder";
import PartnersSlider from "../components/SlickSlider/PartnersSlider";
import { Link } from "react-router-dom";

import useAxiosGet from "../components/Axios/useAxiosGet";

const Home = ({ location }) => {
  const cmsUrl = process.env.REACT_APP_BACKEND_URL;

  const urlGallery = "/galeria";
  const urlNews = "/artykuly?_limit=10&_sort=created_at:DESC";

  const {
    response: gallery,
    isLoading: isLoadingGallery,
    error: errorGallery,
  } = useAxiosGet(urlGallery);

  const {
    response: news,
    isLoading: isLoadingNews,
    error: errorNews,
  } = useAxiosGet(urlNews);

  if (errorNews) console.log(errorNews);
  if (errorGallery) console.log(errorGallery);
  // gallery[Object.keys(gallery).length - 1].zdjecia
  // useEffect(() => {
  //   // Get Gallery

  //   const getGallery = async () => {
  //     try {
  //       await axios.get(`${cmsUrl}/galeria`).then((response) => {
  //         const resp = response.data;
  //         let lastGallery = Object.keys(resp).length - 1;
  //         setGallery(resp[lastGallery].zdjecia);
  //         return setIsLoading(false);
  //       });
  //     } catch (error) {
  //       console.error(error);
  //     }
  //   };

  // Get News

  //   const getNews = async () => {
  //     try {
  //       await axios.get(`${cmsUrl}/artykuly`).then((response) => {
  //         const resp = response.data;
  //         return setNews(resp);
  //       });
  //     } catch (error) {
  //       console.error(error);
  //     }
  //   };
  //   getGallery();
  //   getNews();
  // }, [cmsUrl]);

  const [mobile, setMobile] = useState(false);
  const breakpoint = 768;
  const handleWindowResize = () => {
    if (window.innerWidth < breakpoint) {
      setMobile(true);
    } else {
      setMobile(false);
    }
  };

  useEffect(() => {
    window.addEventListener("resize", handleWindowResize);
    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);
  const GalleryToRender = isLoadingGallery ? (
    <div className="loader"></div>
  ) : (
    gallery[gallery.length - 1].zdjecia
      .slice(0, mobile ? 6 : 10)
      .map((img, i) => (
        <div key={i} className="gallery-item">
          <img src={cmsUrl + img.formats.small.url} alt={img.name} />
        </div>
      ))
  );

  const NewsToRender = isLoadingNews ? (
    <div className="loader"></div>
  ) : (
    news
      .sort((a, b) => new Date(b.data) - new Date(a.data))
      .slice(0, 5)
      .map((news, i) => {
        const imgUrl = news.okladka.formats.small
          ? news.okladka.formats.small.url
          : news.okladka.url;

        return (
          <NewsCard
            key={i}
            src={cmsUrl + imgUrl}
            title={news.tytul}
            description={news.opis ? `${news.opis.slice(0, 120)}...` : ""}
            to={`/aktualnosci/${news.id}`}
            data={news.data}
          />
        );
      })
  );

  let aboutSectionRef = useRef(null);
  return (
    <>
      <Header
        location={location}
        srcDesktop={"/assets/img/header/header-desktop.webp"}
        srcMobile={"/assets/img/header/header-mobile.webp"}
        heading={
          <>
            Najlepszy klub podnoszenia ciężarów <nobr>w Polsce</nobr>
          </>
        }
        refScrollTo={aboutSectionRef}
      />
      {/* o nas */}
      <section id="onas" ref={aboutSectionRef} className="aboutus-section">
        <div className="row-width">
          <div className="abouts-container">
            <Link to="/historia">
              <div className="abouts-item">
                <img src="/assets/icons/ksiazka.svg" alt="historia klubu" />
                <h3>Historia klubu</h3>
              </div>
            </Link>
            <Link to="/zasluzeni">
              <div className="abouts-item ">
                <img src="/assets/icons/medal.svg" alt="zasluzeni" />
                <h3 className="red">Zasłużeni</h3>
              </div>
            </Link>
            <div className="abouts-item">
              <img src="/assets/icons/wykres.svg" alt="statystyki" />
              <h3>Statystyki</h3>
            </div>
          </div>
        </div>
      </section>
      {/* klub */}
      <section className="club-section">
        <div className="row-width">
          <h2>Razem udźwigniemy każdy ciężar!</h2>
          <ButtonBorder
            title={
              <>
                Nabór <nobr> do sekcji </nobr>
              </>
            }
            to={"nabor-do-sekcji"}
            cName="cluba-btn btn-white"
          />
        </div>
        <div className="maskotka">
          <img src="/assets/img/budowlani-mis.png" alt="" />
        </div>
        {/* <div className="bottom-shape shape-club "></div> */}
      </section>

      {/* aktualności */}
      <section className="news-section">
        <div className="row-width">
          <h2>Aktualności</h2>
          <div className="news-container">{NewsToRender}</div>
          <ButtonBorder
            title={"sprawdź"}
            to={"/aktualnosci"}
            cName="news-btn btn-red"
          />
        </div>
        <div className="bottom-shape"></div>
      </section>
      {/* galeria */}
      <section className="gallery-section">
        <h2>Galeria</h2>

        <div className="gallery-container">{GalleryToRender}</div>

        <ButtonBorder
          title={"więcej"}
          to={"/galeria"}
          cName="gallery-btn btn-blue"
        />
      </section>
      {/* sponsorzy */}
      <section className="sponsors">
        <div className="top-shape"></div>
        <div className="row-width">
          <h2>Sponsorzy</h2>
          <div className="sponsors-container">
            <div className="sponsors-group">
              <div className="sponsors-group-item">
                <SponsorCard
                  title="Sponsor tytularny"
                  src="/assets/icons/partners/sponsors/kucera.svg"
                />
              </div>
            </div>
            {/* <div className="sponsors-group">
              <SponsorCard
                title="Sponsor rozgrywek i I ligi"
                src="/assets/icons/partners/sponsors/kucera.svg"
              />
            </div> */}

            <div className="sponsors-group">
              {/* <div className="sponsors-group-item">
                <SponsorCard
                  title="Sponsor główny"
                  src="/assets/icons/partners/sponsors/redos.svg"
                />
              </div> */}
              <div className="sponsors-group-item">
                <SponsorCard
                  title="Sponsor główny"
                  src="/assets/icons/partners/sponsors/carter.svg"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* INSTYTUCJE WSPÓŁFINANSUJĄCE DZIAŁALNOŚĆ  */}
      <section className="institutions-section">
        <div className="row-width">
          <h2>Instytucje współfinansujące działalność</h2>

          <div className="iwd-container">
            <SponsorCard
              cName="iwd-card"
              src="/assets/img/iwd/msit.png"
              alt="Ministerstwo Sportu i Turystyki"
            />
            <SponsorCard
              cName="iwd-card"
              src="/assets/img/iwd/pzpc.jpg"
              alt="Polski Związek Podnoszenia Ciężarów"
            />
            <SponsorCard
              cName="iwd-card"
              src="/assets/img/iwd/wlkp.png"
              alt="Samorząd Województwa wielkopolskiego"
            />
            <SponsorCard
              cName="iwd-card"
              src="/assets/img/iwd/laz.png"
              alt="laz"
              title="Ludowe Zespoły Sportowe"
            />
            <SponsorCard
              cName="iwd-card"
              src="/assets/img/iwd/nt.svg"
              title="Gmina Nowy Tomyśl"
              alt="nt"
            />
            <SponsorCard
              cName="iwd-card"
              src="/assets/img/iwd/pnt.svg"
              title="Powiat nowotomyski"
              alt="pnt"
            />
          </div>
        </div>
      </section>

      {/* partnerzy */}
      <section className="partners-section">
        <div className="row-width">
          <h2>Partnerzy</h2>
          <PartnersSlider className="partners-slider" />
        </div>
      </section>
      {/* instytucje */}
      {/* <section className="institut-section">
        <div className="row-width">
          <h2>Instytucje współpracujące</h2>
          <PartnersSlider />
        </div>
      </section> */}
    </>
  );
};

export default Home;
