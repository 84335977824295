import React from "react";
import "./_search.scss";

const Search = ({ className, searchQuery, setSearchQuery }) => {
  return (
    <>
      <div className={`${className}  search-bar`}>
        <label htmlFor="search-bar"></label>
        <input
          type="text"
          name="s"
          placeholder="szukaj artykułu"
          value={searchQuery}
          onInput={(e) => {
            setSearchQuery(e.target.value.trimStart());
          }}
        />
        <div>
          <img src="/assets/icons/szukaj.svg" alt="szukaj" />
        </div>
      </div>
    </>
  );
};
export default Search;
