import { useState, useEffect } from "react";
import axios from "axios";

const useAxiosGet = (urlRequest) => {
  const cmsUrl = process.env.REACT_APP_BACKEND_URL;

  const [response, setResponse] = useState([]);
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const urlData = cmsUrl + urlRequest;

  useEffect(() => {
    const getData = async () => {
      await axios
        .get(urlData)
        .then((response) => {
          const resp = response.data;

          setResponse(resp);
        })
        .catch((error) => {
          setError(error);
          setIsLoading(true);
        });
      setIsLoading(false);
    };
    getData();
  }, [urlData]);

  return { response, error, isLoading, cmsUrl };
};

export default useAxiosGet;
