import React from "react";
import HeaderPages from "../components/Header/HeaderPages";
import "./_siteInWork.scss";
const SiteInWork = () => {
  return (
    <>
      {" "}
      <HeaderPages
        title="Dołącz do nas"
        src={"/assets/img/header-pages/news.jpg"}
      />
      <section className="site-in-work">
        <div className="row-width">
          <div className="site-in-work">
            <h2>Podstrona w budowie</h2>
          </div>
        </div>
      </section>
    </>
  );
};

export default SiteInWork;
