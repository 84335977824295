import React, { useState } from "react";
import "./_contact.scss";
import HeaderPages from "../components/Header/HeaderPages";
import ButtonBorderNoLink from "../components/Buttons/ButtonBorderNoLink";
import axios from "axios";

const Contact = () => {
  const API_PATH = "https://budowlani-kucera.pl/api/contact/index.php";

  const [formData, setFormData] = useState({
    firstname: "",
    surname: "",
    email: "",
    message: "",
    mailSent: false,
    error: null,
  });

  const [validation, setValidation] = useState({
    firstname: true,
    surname: true,
    email: true,
    message: true,
  });

  const [messageBack, setMessageBack] = useState("");

  function handelOnChagne(e) {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  }

  function handelValidation(e) {
    if (e.target.name !== "email") {
      if (e.target.value) {
        return setValidation({ ...validation, [e.target.name]: true });
      } else return setValidation({ ...validation, [e.target.name]: false });
    }

    if (/\S+@\S+\.\S+/.test(formData.email)) {
      setValidation({ ...validation, email: true });
    } else setValidation({ ...validation, [e.target.name]: false });
  }

  function handelMailValidation(e) {
    if (/\S+@\S+\.\S+/.test(e.target.value)) {
      setValidation({ ...validation, email: true });
    } else setValidation({ ...validation, [e.target.name]: false });
  }

  function handelSend(e) {
    e.preventDefault();

    let validationForm = { ...validation };

    if (formData.firstname) {
      validationForm.firstname = true;
    } else validationForm.firstname = false;

    if (/\S+@\S+\.\S+/.test(formData.email)) {
      validationForm.email = true;
    } else validationForm.email = false;

    if (formData.message) {
      validationForm.message = true;
    } else validationForm.message = false;

    setValidation({ ...validationForm });

    if (
      validationForm.firstname &&
      validationForm.email &&
      validationForm.message
    ) {
      setMessageBack("");

      axios({
        method: "post",
        url: `${API_PATH}`,
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        data: formData,
      })
        .then((result) => {
          console.log(result.data);
          setMessageBack("Dziękujemy za wiadomość!");
        })
        .catch((error) => {
          setMessageBack("błąd, wiadomość niewysłana");
          console.log(error);
        });
    } else setMessageBack("uzupełnij formularz poprawnie");
  }

  return (
    <>
      <HeaderPages src={"/assets/img/header-pages/news.jpg"} />
      <section className="contact-section">
        <div className="row-width">
          <h2>Kontakt</h2>

          <form action="#" className="contact-form">
            <div
              className={validation.firstname ? "" : "alert-validation"}
              data-validation="podaj imię"
            >
              <input
                type="text"
                name="firstname"
                placeholder="imię"
                value={formData.firstname}
                onChange={(e) => {
                  handelOnChagne(e);
                  handelValidation(e);
                }}
                onBlur={(e) => {
                  handelValidation(e);
                }}
              />
            </div>
            <div>
              <input
                type="text"
                name="surname"
                placeholder="nazwisko"
                value={formData.surname}
                onChange={(e) => handelOnChagne(e)}
              />
            </div>
            <div
              className={validation.email ? "" : "alert-validation"}
              data-validation="xyz@xyz.xyz"
            >
              <input
                type="text"
                name="email"
                placeholder="email"
                value={formData.email}
                onChange={(e) => {
                  handelOnChagne(e);
                  handelMailValidation(e);
                }}
                onBlur={(e) => {
                  handelMailValidation(e);
                }}
              />
            </div>

            <div
              className={validation.message ? "" : "alert-validation"}
              data-validation="napisz wiadomość"
            >
              <textarea
                name="message"
                placeholder="wiadomość"
                id=""
                value={formData.message}
                onChange={(e) => {
                  handelOnChagne(e);
                  handelValidation(e);
                }}
                onBlur={(e) => {
                  handelValidation(e);
                }}
              ></textarea>
            </div>
            <div>
              <ButtonBorderNoLink
                onClick={(e) => handelSend(e)}
                cName="btn-blue contact-btn"
                title="wyślij"
              />
            </div>
            <div className="contact-form-message-back">
              <p>{messageBack}</p>
            </div>
          </form>
        </div>
      </section>
    </>
  );
};
export default Contact;
