import React from "react";
import "./_volunteersCard.scss";
import ReactMarkdown from "react-markdown";

const ManagementCard = ({
  name,
  imgManager,
  alt,
  position,
  contact,
  mail,
  text,
}) => {
  return (
    <>
      <div className="volunteers-p-container">
        <div className="volunteers-item">
          <h3 className="volunteers-name">{name}</h3>
          <div>
            <img
              src={imgManager}
              alt={alt}
              className="volunteers-info-data-coverImg"
            />
          </div>
          <div className="volunteers-item-info">
            {position ? <h3>{position}</h3> : ""}
            {contact ? (
              <div className="volunteers-info-data">
                <img src="/assets/icons/telefon.svg" alt="kontakt" />

                <span>{contact}</span>
              </div>
            ) : (
              ""
            )}
            {mail ? (
              <a itemProp="email" href={`mailto:${mail}`}>
                <div className="volunteers-info-data">
                  <img src="/assets/icons/mail.svg" alt="mail" />
                  <span>{mail}</span>
                </div>
              </a>
            ) : (
              ""
            )}
          </div>
        </div>
        <div className="volunteers-person-text">
          <ReactMarkdown
            components={{
              // Map `h1` (`# heading`) to use `h2`s.
              h1: "h2",
            }}
          >
            {text}
          </ReactMarkdown>
        </div>
      </div>
    </>
  );
};
export default ManagementCard;
