import React, { useEffect, useState } from "react";
import "./_playerSingel.scss";
import HeaderPages from "../components/Header/HeaderPages";
import { useParams } from "react-router-dom";
// import { SRLWrapper } from "simple-react-lightbox";

import ButtonBorder from "../components/Buttons/ButtonBorder";
import PlayerPageCard from "../components/Cards/PlayerPageCard";

import axios from "axios";

// const options = {
//   settings: {
//     // overlayColor: "rgb(25, 136, 124)",
//     autoplaySpeed: 4000,
//     transitionSpeed: 900,
//   },
//   buttons: {
//     backgroundColor: "rgba(30,30,36,0.8)",
//     iconColor: "rgba(255, 255, 255, 0.8)",
//     iconPadding: "10px",
//     showAutoplayButton: true,
//     showCloseButton: true,
//     showDownloadButton: false,
//     showFullscreenButton: true,
//     showNextButton: true,
//     showPrevButton: true,
//     showThumbnailsButton: true,
//     size: "40px",
//   },
//   // caption: {
//   //   captionColor: "#a6cfa5",
//   //   captionFontFamily: "Raleway, sans-serif",
//   //   captionFontWeight: "300",
//   //   captionTextTransform: "uppercase",
//   // },
//   // progressBar: {
//   //   height: "20px",
//   //   fillColor: "blue",
//   //   backgroundColor: "white",
//   // },
// };

const PlayerSingel = () => {
  const { slug } = useParams();
  const cmsUrl = process.env.REACT_APP_BACKEND_URL;

  const [player, setPlayer] = useState([]);
  const [playerImg, setPlayerImg] = useState([]);
  const [sponsorLogo, setSponsorLogo] = useState([]);

  useEffect(() => {
    // Get Player

    const getPlayer = async () => {
      try {
        await axios.get(`${cmsUrl}/zawodnicy/${slug}`).then((response) => {
          const resp = response.data;
          setPlayerImg(resp.zdjecie_profilowe.url);
          setSponsorLogo(resp.logo_sponsora[0].url);
          return setPlayer(resp);
        });
      } catch (error) {
        console.error(error);
      }
    };
    getPlayer();
  }, [cmsUrl, slug]);

  return (
    <>
      <HeaderPages src={"/assets/img/header-pages/news.jpg"} />
      <section className="player-section-p">
        <div className="row-width">
          <PlayerPageCard
            name={player.imie_nazwisko}
            imgPlayer={cmsUrl + playerImg}
            alt={player.alt_tag_zdjecia}
            year={player.rocznik}
            category={player.kategoria}
            record={player.rekord}
            sponsor={player.sponsor}
            text={player.opis}
            imgSponsor={cmsUrl + sponsorLogo}
          />
          <ButtonBorder
            title={"zawodnicy"}
            to={"/zawodnicy#zawodnicy"}
            cName="btn-blue btn-player-singel"
          />
        </div>
      </section>
    </>
  );
};
export default PlayerSingel;
