import React from "react";
import "./_pagination.scss";
import PaginationLogic from "./PaginationLogic2";

const Pagination2 = ({
  searchQuery,
  loading,
  data,
  postsPerPage,
  numberOfPages,
}) => {
  const {
    activePage,
    CurrentPages,
    currentPosts,
    lastPageNumber,
    numberOfPagesRatio,
    handelPrevPage,
    setActivePage,
    handelNextPage,
  } = PaginationLogic({
    searchQuery,
    loading,
    data,
    postsPerPage,
    numberOfPages,
  });

  const pageNumbersToRender = (
    <nav>
      <ul className="pagination">
        <li className="pagination-prev " onClick={() => handelPrevPage()}></li>

        <ul className="page-numbers">
          {activePage >= numberOfPages ? (
            <>
              <li
                className={
                  activePage === 1 ? "page-item page-item-active" : "page-item"
                }
                onClick={() => setActivePage(1)}
              >
                1
              </li>
              <li>...</li>
            </>
          ) : (
            ""
          )}

          {CurrentPages.map((page, i) => (
            <li
              key={i}
              className={
                activePage === page ? "page-item page-item-active" : "page-item"
              }
              onClick={() => setActivePage(page)}
            >
              {page}
            </li>
          ))}
          {activePage >= lastPageNumber - numberOfPagesRatio + 1 ? (
            ""
          ) : (
            <>
              <li>...</li>
              <li
                className={
                  activePage === lastPageNumber
                    ? "page-item page-item-active"
                    : "page-item"
                }
                onClick={() => setActivePage(lastPageNumber)}
              >
                {lastPageNumber}
              </li>
            </>
          )}
        </ul>
        <li className="pagination-next" onClick={() => handelNextPage()}></li>
      </ul>
    </nav>
  );
  return { currentPosts, pageNumbersToRender, setActivePage };
};
export default Pagination2;
