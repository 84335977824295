import React, { useEffect, useState } from "react";
import "./_players.scss";
import HeaderPages from "../components/Header/HeaderPages";
import axios from "axios";

import PlayerCard from "../components/Cards/PlayerCard";

const Players = () => {
  const cmsUrl = process.env.REACT_APP_BACKEND_URL;
  const pathUrl = "/zawodnicy";

  const [players, setPlayers] = useState([]);

  useEffect(() => {
    // Get News

    const getPlayers = async () => {
      try {
        await axios.get(cmsUrl + pathUrl).then((response) => {
          const resp = response.data;
          return setPlayers(resp);
        });
      } catch (error) {
        console.error(error);
      }
    };
    getPlayers();
  }, [cmsUrl]);

  const PlayersToRender = players.map((player, i) => {
    let playerImg;

    if (player.zdjecie_profilowe) {
      playerImg = cmsUrl + player.zdjecie_profilowe.formats.small.url;
    } else playerImg = "/assets/img/budowlani-mis.png";

    return (
      <PlayerCard
        id={player.id}
        key={i}
        imgPlayer={playerImg}
        alt={player.alternativeText}
        name={player.imie_nazwisko}
      />
    );
  });
  return (
    <>
      <HeaderPages src={"/assets/img/header-pages/news.jpg"} />
      <section id="zawodnicy" className="players-section-p">
        <div className="row-width">
          <h2>Zawodnicy</h2>
          <div className="players-p-container">{PlayersToRender}</div>
        </div>
      </section>
    </>
  );
};
export default Players;
