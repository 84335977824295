import { useState } from "react";
import "./_pagination.scss";

const PaginationLogic2 = ({ isLoading, data, postsPerPage, numberOfPages }) => {
  data = !isLoading
    ? data.sort((a, b) => Date.parse(b.data) - Date.parse(a.data))
    : "isLoading...";

  const [activePage, setActivePage] = useState(1);

  const totalPosts = !isLoading ? data.length : "isLoading...";

  const numberOfPagesRatio = Math.ceil(numberOfPages / 2);

  const lastPageNumber = !isLoading
    ? Math.ceil(totalPosts / postsPerPage)
    : false;

  let lastIndexOfPost = postsPerPage * activePage;

  if (activePage % numberOfPages === 0)
    lastIndexOfPost = activePage * postsPerPage;

  const firstIndexOfPost = lastIndexOfPost - postsPerPage;
  let currentPosts;

  if (data) {
    currentPosts = data.slice(firstIndexOfPost, lastIndexOfPost);
  }

  const pageNumbers = [];

  for (let i = 1; i <= Math.ceil(totalPosts / postsPerPage); i++) {
    pageNumbers.push(i);
  }

  function handelPrevPage() {
    if (activePage === 1) return;

    setActivePage(activePage - 1);
  }

  function handelNextPage() {
    if (activePage === lastPageNumber) return;

    setActivePage(activePage + 1);
  }

  const lastIndexPage =
    activePage < numberOfPages
      ? numberOfPages
      : activePage + numberOfPagesRatio - 1;
  const firstIndexPage =
    activePage < numberOfPages ? 0 : activePage - numberOfPagesRatio;
  let CurrentPages = pageNumbers.slice(firstIndexPage, lastIndexPage);

  // if (lastPageNumber - numberOfPagesRatio < activePage)
  //   CurrentPages = pageNumbers.slice(lastPageNumber - numberOfPages);

  return {
    activePage,
    numberOfPages,
    CurrentPages,
    currentPosts,
    lastPageNumber,
    numberOfPagesRatio,
    handelPrevPage,
    setActivePage,
    handelNextPage,
  };
};
export default PaginationLogic2;
